const config = {
  /* server_url: 'https://medgpt360.com', */
  server_url_PROD: 'https://medgpt360.guidelines-bot.com',
  server_url_DEV: 'http://localhost:9079',
  server_url: 'https://medgpt360.com',


  ga4_ID_PROD: 'G-M8MD4B8EYL',
  ga4_DEV: 'G-1111',
  ga4_ID: 'G-M8MD4B8EYL'


  // Add more configuration properties as needed
};

export default config;