import React, { useState } from 'react';
import Modal from 'react-modal';
 
Modal.setAppElement('#root'); // Set the app root element for screen reader accessibility

const AIObservationModal = ({ isOpen, onRequestClose , onGenerate}) => {
  const [email, setEmail] = useState('');
  const [result, setResult] = useState('');
  const [showSuccess, setShowSuccess] = useState(false); // State for success message
  const [isEmailValid, setIsEmailValid] = useState(false); // State for custom email validation
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState(''); // Error message state

  const handleGenerateClick = async () => {
    try {
      // Perform any necessary validation on the email input
      if (isEmailValid) {
        // Call the parent's handler with the email
        onGenerate(email);
        setShowSuccess(true);
      } else {
        // Handle the case when email is empty
        setResult('Please enter an email address.');
      }
    } catch (error) {
      setResult('Error: Something went wrong.');
    }
  };

  
  // Function to handle email input change
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value.trim(); // Trim the input to remove leading and trailing spaces
     
    setEmail(enteredEmail);

    // Validate the email using a simple regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValid = emailPattern.test(enteredEmail);

    setIsEmailValid(isValid);
    setIsButtonDisabled(!isValid);


     // Update the email error message
     if (!isValid) {
        setEmailErrorMessage('Please enter a valid email address');
      } else {
        setEmailErrorMessage('');
      }

  };


  const handleClose = () => {
    setEmail('');
    setResult('');
    setShowSuccess(false);
    setIsButtonDisabled(true);
    onRequestClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="AIObservations Modal">

      <div className="container-fluid">
      <div className="row">
        <div className="modal-image  col-md-6 col-lg-6">
          <img src="/AIObservationsSample.png" alt="Image" className="img-fluid" />
        </div>
        
        <div className="modal-form col-md-6 col-lg-6">
            {showSuccess ? (
              <div>
                <h2 className="mb-4 text-success">AI Observations Started!</h2>
                <p>The process of generating AI Observations and AI thoughts on the top references of your report has started.</p><p>You will receive an email at <span style={{ color: 'blue' }}>{email}</span> once the results are ready. You can close this dialog.</p>
                <button onClick={handleClose} className="btn btn-primary">
                  Close
                </button>
              </div>
            ) : (
              <> 
                <h2 className="mb-4">Let AI do the reading for you</h2>
                  <p>Would you like to generate a summary of the top references for your report, tailored to the diagnosis, stage (if relevant), and age range? (as exemplified by the AI Observations, findings and thoughts in the image)  </p>
                  <p style={{fontWeight:'600', color:'purple'}}>This can greatly assist you in understanding the most pertinent information related to your report, making the process more efficient and informative.</p>
              <p>Just enter your email address to receive a notification once the results are ready!</p>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleEmailChange}
                  className="form-control mb-3"
                  required 
                />
                 {emailErrorMessage && <div className="text-warning">{emailErrorMessage}</div>}
                <button onClick={handleGenerateClick} className="btn btn-primary" disabled={isButtonDisabled}>
                  Generate
                </button>   
                <button onClick={handleClose} className="btn btn-cancel">
                  Cancel
                </button>
                <div className="result mt-3">{result}</div>
              </>
            )}
          </div>
      </div>
      </div>
    </Modal>
  );
};

export default AIObservationModal;
