 
import { Dropdown, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

function NavigationMenu({
  setPersoPageVisible,
  setDiseaseInfoVisible,
  setBiomarkersInfoVisible,
  setRxInfoVisible,
  setLifestyleInfoVisible,
  setExpertsInfoVisible,
  setReferencesInfoVisible,
  isBiomarkerLoading,
  isTreatmentLoading,
  isLifestyleLoading,
  handleTabChange 
}) {
  const location = useLocation();
  const persoParam = new URLSearchParams(location.search).get('perso');
  if (persoParam) {

  }
 
    const scrollToTop = () => {
       
        //    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
         
            window.scrollTo({ top: 0, behavior: 'smooth' });
          
        }
   
      

  const handleItemClick = (eventKey) => {
    handleTabChange(eventKey);
   // handleTabChange(eventKey);

  };

  return (
    <div className="report-navigation  d-md-none">
      <Nav className="menu-items">
        <Dropdown>
          <Dropdown.Toggle variant="link" id="navigation-dropdown">
            Menu
          </Dropdown.Toggle>
          <Dropdown.Menu>
          {persoParam ? (    <Dropdown.Item
              eventKey="persoPage"
              onClick={() => handleItemClick('persoPage')}
            >
              
              Perso Insights  <FontAwesomeIcon icon={faLightbulb } style={{ color: 'darkorange' , textShadow: '0 0 8px rgba(255, 255, 255, 0.8)', }} />
            </Dropdown.Item>) : null}
            <Dropdown.Item
              eventKey="diseaseInfo"
              onClick={() => handleItemClick('diseaseInfo')}
            >
              
              Disease Info
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="biomarkers"
              onClick={() => handleItemClick('biomarkers')}
              style={{
                color:  isBiomarkerLoading ? 'gray' : 'inherit', 
            
                cursor:(isBiomarkerLoading )  ? 'default' : 'pointer',
              }}
            >
            
              Biomarkers       {isBiomarkerLoading && (
                  <FontAwesomeIcon icon={faSpinner} spin />
                )}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="treatments"
              onClick={() => handleItemClick('treatments')}
              style={{
                color: ( isBiomarkerLoading || isTreatmentLoading) ? 'gray' : 'inherit', 
            
                cursor:(isBiomarkerLoading || isTreatmentLoading )  ? 'default' : 'pointer',
              }}
              disabled={isTreatmentLoading}
            >  
            
       
              Treatments       {isTreatmentLoading && (
                <FontAwesomeIcon icon={faSpinner} spin />
              )}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="lifestyles"
              onClick={() => handleItemClick('lifestyles')}
              style={{
                color: ( isBiomarkerLoading ||  isTreatmentLoading || isLifestyleLoading) ? 'gray' : 'inherit', 
            
                cursor:(isBiomarkerLoading || isTreatmentLoading || isLifestyleLoading)  ? 'default' : 'pointer',
              }}
            >   
              Lifestyles {isLifestyleLoading && (
                <FontAwesomeIcon icon={faSpinner} spin />
              )}
            </Dropdown.Item>
     
            <Dropdown.Item
              eventKey="references"
              onClick={() => handleItemClick('references')}
            >
              References
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="sendFeedback"
              onClick={() => handleItemClick('sendFeedback')}
            >
              Send Feedback
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </div>
  );
}

export default NavigationMenu;
