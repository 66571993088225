import React, {useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const MedicationPopupButton = ({ data, label }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

 
  const handleButtonClick = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <button
        style={{ display: "inline-block", margin: "5px", padding: "10px" }}
        onClick={handleButtonClick}
      >
        <span role="img" aria-label="Medication Icon">
          🩹
        </span>{' '}
        {label}
      </button>

      
      
<Modal dialogClassName="reference-modal" show={isPopupOpen} onHide={handleClosePopup} size="lg" >
        <Modal.Header closeButton>
          <Modal.Title>{data.medication_class}</Modal.Title>
        </Modal.Header>
        <Modal.Body  style={{ maxHeight: '78vh', overflowY: 'auto' }}>
          {/* Render the row-specific content here */}
          {data.brand_names.length > 0 && (   <p><strong>Medications:</strong> 
  


          
           <ul>
    {data.brand_names.map((brand, index) => (

        
      <li key={index} style={{ verticalAlign: 'middle', marginBottom: '5px' }}>
       
       <a     title={`Open ${brand} on Felleskatalogen`} href={`https://www.felleskatalogen.no/medisin/internsok?sokord=${encodeURIComponent(brand)}`} target="_blank" rel="noopener noreferrer">
          <img src={'https://www.felleskatalogen.no/resources/image/fk-logo-icon-f63fac106422b12d6e1d33e4866b7523.png'} alt={`Felleskatalogen favicon`} style={{ marginRight: '5px', width: '16px', height: '16px',   }} />
        </a>&nbsp;<a title={`Search ${brand} on European Medicines Agency`}  href={`https://www.ema.europa.eu/en/search?f%5B0%5D=ema_medicine_bundle%3Aema_medicine&f%5B1%5D=ema_search_categories%3A83&f%5B2%5D=ema_search_categories%3A84&search_api_fulltext=${encodeURIComponent(brand)}`} target="_blank" rel="noopener noreferrer">
        <img src={process.env.PUBLIC_URL + "/images/ema-favicon.png"} alt={`EMA favicon`} style={{ marginRight: '5px', width: '16px', height: '16px',    }} />
        </a>&nbsp;<a title={`Search ${brand} on Google`}  href={`https://www.google.com/search?q=${encodeURIComponent(brand)}`} target="_blank" rel="noopener noreferrer">
        <img src={'https://www.google.com/images/branding/googleg/1x/googleg_standard_color_128dp.png'} alt={`Felleskatalogen favicon`} style={{ marginRight: '5px', width: '16px', height: '16px',   }} />
        
        </a>&nbsp;{brand}
        

      </li>
    ))}
  </ul></p>)}
          <p><strong>Generic Name:</strong>  {data.generic_name}  (<a
  href={`https://www.ema.europa.eu/en/medicines/field_ema_web_categories%253Aname_field/Human/ema_group_types/ema_medicine/search_api_aggregation_ema_therapeutic_area_name/${encodeURIComponent(data.indication)}?search_api_views_fulltext=${encodeURIComponent(data.generic_name)}`}
  target="_blank"
  rel="noopener noreferrer"
>
search EMA
</a>)</p>

      


          <p><strong>Indication:</strong> {data.indication}</p>
          {data.decision && (  <p><strong>Decision:</strong> {data.decision}</p>)}
          <p><strong>Evidence:</strong> {data.evidence}</p>
          {/* Add more content specific to each row */}
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
    </>
  );
};

export default MedicationPopupButton;
