
import React, { useState, useEffect  } from 'react';
import config from './../config';
import NewReportButton from './NewReportButton';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function BrowsePage() {
    const server_url = config.server_url;
    const [allReports, setAllReports] = useState();
   
    const filterByCategory = diseaseCategory => {

        if(allReports==null)
             return [];

   
        const filteredReports = allReports.filter(r => {
            return r.diseaseCategory === diseaseCategory;
          });

          return filteredReports;
       
      }

    useEffect(() => {
        document.title = 'Browse Scientific Overviews';

        fetch(`${server_url}/api/all`)
            .then(res => res.json())
            .then(data => setAllReports(data)).catch(error => {
                console.error('Error fetching data from server:', error);
            });


    }, []);


  return (
    <div className="about-container">

<div style={{margin:'8px', textAlign:'center'}}>
    <NewReportButton></NewReportButton>
    </div>

      <h2  style={{margin:'18px', textAlign:'center'}}>Browse popular reports</h2>
      <Row>
<Col>
<h3>Cancer - Solid Tumor</h3>
      <ul>
    
    { filterByCategory("solid tumor").map((row) => (
    <li><a  href={'/report/'+row.id}  > {row.name}</a></li>))}

  

    </ul>
</Col>
<Col>

<h3>Cancer - Liquid Tumor</h3>
      <ul>
    
    { filterByCategory("liquid tumor").map((row) => (
    <li><a  href={'/report/'+row.id}  > {row.name}</a></li>))}


    </ul>
    
    <h3>Auto-immune diseases</h3>
      <ul>
      { filterByCategory("auto-immune disease").map((row) => (
    <li><a  href={'/report/'+row.id}  > {row.name}</a></li>))}

 

    </ul>
      <h3>Neurodegenerative diseases</h3>
      <ul>     { filterByCategory("neurodegenerative disease").map((row) => (
    <li><a  href={'/report/'+row.id}  > {row.name}</a></li>))}
 


    </ul>


    <h3>Non-communicable diseases</h3>
    <ul>
    { filterByCategory("non-communicable disease").map((row) => (
    <li><a  href={'/report/'+row.id}  > {row.name}</a></li>))}
 

    </ul>

    <h3>Infectious diseases</h3>
    <ul>
    { filterByCategory("infectious disease").map((row) => (
    <li><a  href={'/report/'+row.id}  > {row.name}</a></li>))}
 

    </ul>

    <h3>Custom</h3>

    <a  href='/'  >Generate your report (any disease)</a>
</Col>
      </Row>


      </div>  );
}

export default BrowsePage;
