import React, { useState } from 'react';
 
import { Modal, Button } from 'react-bootstrap';


const LiveUpdatesModal = ({ isOpen, onRequestClose , onGenerate}) => {
  const [email, setEmail] = useState('');
  const [result, setResult] = useState('');
  const [showSuccess, setShowSuccess] = useState(false); // State for success message
  const [isEmailValid, setIsEmailValid] = useState(false); // State for custom email validation
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState(''); // Error message state
  const [eList, setEList] = useState( []); // Error message state

  const handleGenerateClick = async () => {
    try {
      // Perform any necessary validation on the email input
      if (isEmailValid) {
        setShowSuccess(true);

        // Call the parent's handler with the email
        const eList = await onGenerate(email);
        setEList(eList);
        console.log('eList', eList);
      
      } else {
        // Handle the case when email is empty
        setResult('Please enter an email address.');
      }
    } catch (error) {
      setResult('Error: Something went wrong.');
    }
  };

  
  // Function to handle email input change
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value.trim(); // Trim the input to remove leading and trailing spaces
     
    setEmail(enteredEmail);

    // Validate the email using a simple regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValid = emailPattern.test(enteredEmail);

    setIsEmailValid(isValid);
    setIsButtonDisabled(!isValid);


     // Update the email error message
     if (!isValid) {
        setEmailErrorMessage('Please enter a valid email address');
      } else {
        setEmailErrorMessage('');
      }

  };


  const handleClose = () => {
    setEmail('');
    setResult('');
    setShowSuccess(false);
    setIsButtonDisabled(true);
    onRequestClose();
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose}   >
   <Modal.Header closeButton>
        <Modal.Title>Treatment options - What's new?</Modal.Title>
      </Modal.Header>
<Modal.Body>
      
        <div className="modal-form  " >
            {showSuccess ? (
              <div>
                <h3 className="mb-4 text-success">Checking for Updates Started!</h3>
                 <p>You will receive an email at <span style={{ color: 'blue' }}>{email}</span> once the results are ready. You can close this dialog.</p>
              
              </div>
            ) : (
              <> 
                 
                  <p>MedGPT360 will fetch the most up-to-date references and discover the latest treatment options.</p>
              <p>Just enter your email address to receive a notification once the results are ready! (it takes 2 minutes)</p>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleEmailChange}
                  className="form-control mb-3"
                  required 
                />
                 {emailErrorMessage && <div className="text-warning">{emailErrorMessage}</div>}
             
                <div className="result mt-3">{result}</div>
              </>
            )}
          </div>
          </Modal.Body>
          <Modal.Footer>
 

        {showSuccess ? (     <button onClick={handleClose} className="btn btn-primary">
                  Close
                </button>  ) : (<> 
       <Button variant="secondary" onClick={handleClose}>
       Cancel
       </Button>
       <Button variant="primary" onClick={handleGenerateClick}>
         Submit
       </Button>    </>  )}
      </Modal.Footer>
    </Modal>
  );
};

export default LiveUpdatesModal;
