import React from 'react';
import clipboardCopy from 'clipboard-copy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';

class ShareButton extends React.Component {
    handleShareClick = () => {
      const textToCopy = window.location.href; // Replace with the text you want to copy
  
      clipboardCopy(textToCopy)
        .then(() => {
          toast.success('Report URL copied to clipboard!', { position: toast.POSITION.TOP_RIGHT });
        })
  
        .catch((error) => {
          toast.error('Failed to copy text to clipboard!');
          console.error('Error copying text to clipboard:', error);
        });
    };
  
    render() {
      return (
        <div className=" ">
          <button className="share-button" title="Copy the link URL to the clipboard" onClick={this.handleShareClick}> <FontAwesomeIcon className="share-icon" icon={faShare} /></button>
          <ToastContainer />
        </div>
      );
    }
  }
  
  export default ShareButton;