import React from 'react';
 
 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faFileAlt, faPlusCircle,faFile } from '@fortawesome/free-solid-svg-icons';
 
class NewReportButtonNoText extends React.Component {
   
  
 

  
    render() {
      return (
        
       <a  href="/"> <button className="new-report-button" title="New Report: Generate your Scientific Overview (any disease)"> <FontAwesomeIcon className="share-icon" icon={faFile} />  </button></a>  
     
      );
    }
  }
  
  export default NewReportButtonNoText;