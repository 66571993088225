import logo from './logo.svg';
import './App.css';
import config from './config';
import ReactGA from "react-ga4";

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes,  Route,  Link , Navigate   } from 'react-router-dom';
 
import FeedbackForm from './components/FeedbackForm';
import PersonaliseDiseaseInfoForm from './components/PersonaliseDiseaseInfoForm'; 
import Report from './components/report';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import AboutPage from './components/About';
import Privacy from './components/Privacy';
import BrowsePage from './components/Browse';

function App() {
  const [data, setData] = useState();
 
  const [feedback, setFeedback] = useState({accuracy: '', helpfulness:'', note:''});
  const [overview, setOverview] = useState({ id: '', reportName:'', modifiedDate:''});
  const [perso, setPerso] = useState( );
  const [caseDescription, setCaseDescription] = useState({ mainCondition: '', stage: '', ageRange:'' });
  const [allReports, setAllReports] = useState();
  const [isLoading, setIsLoading] = useState(false);
   
  const [isStep1Loading, setIsStep1Loading] = useState(null);
  const [isStep1Completed, setIsStep1Completed] = useState(false);
  const [isStep2Empty, setIsStep2Empty] = useState(false);
  const [isError, setIsError] = useState(false);
  const loadingMessages = ['Generating disease information...', 'Verify age group...', 'Diagnosis Work-up...','Key Points for Clinicians...','Key Points for Patients...','Generating Biomarkers...', 'Emerging biomarkers...', 'Generating Treatments...', 'Emerging Treatments...', 'Generating Lifestyles....', 'Generating Experts...', 'Please wait...', 'Formatting the report...',  'Final touches...', 'Waiting for GPT ...', 'Almost ready...', ];
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const server_url = config.server_url;
 
  ReactGA.initialize(config.ga4_ID);


  useEffect(() => {
    let timer;

    if (isLoading) {
      timer = setInterval(() => {
        setCurrentMessageIndex((prevIndex) =>
          (prevIndex + 1) % loadingMessages.length
        );
      }, 5000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isLoading]);

  useEffect(() => {
//&& isStep2Empty
    if (isStep1Completed ) {
    //  console.log("Process results after isStep1Completed ");
      setIsLoading(false);
      if (data == null) {
        console.log("Data is null - return.")
        return;
      }
      var caseId = null;
 
   //   console.log("set overview when no instruction " + data);
      caseId = data.id;
      setOverview(data);


   //   console.log("newUrl page");
      const newUrl = `/report/${caseId}`;
      window.history.pushState({ caseId }, "", newUrl);

      setCaseDescription(data.caseDescription);
    } 

  }, [isStep1Completed]);


  const handleFeedbackSubmit = (formData) => {
  
    feedback.accuracy=formData.accuracy;
    feedback.helpfulness=formData.helpfulness;
    feedback.note=formData.note;
    feedback.scientificOverviewId = overview.id;
   feedback.pageWhenReporting = window.location.pathname;
    feedback.reportWhenReporting =overview.reportName ;

    fetch('${server_url}/api/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(feedback)
    })
    .then(response => response.json())
    .then(data => {
      console.log("feedback saved", data);    });
     
  };

  const validateDisease = (formData) =>{
    
    if(formData.mainCondition==""){
      console.log("main condition is empty, skip validation");
      return;
    }

    fetch(`${server_url}/api/validation/case_description`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        //console.log("checked if disease:",data);
        if(data.isDisease==false){
          setIsError(true);
        }
  });


  }

  const processOverviewResults = (formData, data) => {
    setIsLoading(false);
    if (data == null) {
      console.log("processOverviewResults - Data is null - return.")
      return;
    }
    var caseId = null;
    // Set the ID in the URL
    //console.log("Method endpoint returned with ID:", caseId);
    if (formData.instructions == null || formData.instructions.length == 0) {
   //   console.log("set overview when no instruction " + data);
      caseId = data.id;
      setOverview(data);
    } else {
      if (data.overview != null) {
        caseId = data.overview.id;
        setOverview(data.overview);
       // console.log("data.overview", data.overview);
      }
      setPerso(data.perso);

     // console.log("set perso", data.perso);
    }



   // console.log("formData.instructions", formData.instructions);
    if (formData.instructions != null) {
      console.log("newUrl page");
      const newUrl = `/report/${caseId}`;
      window.history.pushState({ caseId }, "", newUrl);
    }
    setCaseDescription(data.caseDescription);
  }

  // Define a function to handle the fetch and retry logic
function fetchAndRetry(url,formData) {
  // Counter to keep track of the number of attempts
  let retryCount = 0;

  // Define a recursive function to make the fetch call
  function fetchWithRetry( ) {
    console.log(url + " call "+retryCount);
 

    fetch(url)
      .then((response) => response.json())
      .then((newData) => {
        console.log("call returned.");
        // Handle the response of the fetch call
        if (isStep1Loading!=null && isStep1Loading && newData.generalInfo == null && retryCount < 10) {
          // Retry after 1000 milliseconds
          retryCount++;
          console.log("Retry " + retryCount);
          setTimeout(fetchWithRetry, 1000);
        } else {
          // Either no longer in progress or reached the maximum number of retries
          if (!newData.inProgress || newData.generalInfo != null) {
             console.log("Process results after retry");
            processOverviewResults(formData, newData);
          } else {
            console.log("Maximum retries reached. Stopping.");
          }
        }
      })
      .catch((error) => {
        console.error('Error during fetch:', error);
      });
  }

  // Start the initial fetch call
  fetchWithRetry();
}

  const handleStep1Submit = (formData) => {
    setIsStep1Loading(true);
    var formDataToSend = formData;
    var URL = `${server_url}/api/report/by_case_description?sendTo=${encodeURIComponent(formData.email)}`;

    //    console.log("from PersonalisedDiseaeInfoForm STEP1, call " + URL);
    fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formDataToSend),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsStep1Loading(false);
        setIsStep1Completed(true);
      //   console.log("STEP 1 returned " , data);
        setOverview(data);
      })
      .catch(error => {
        setIsStep1Loading(false);
        console.log(error);
      });

  }


  const handleCaseDescriptionSubmit = (formData) => {
    setIsLoading(true);
    setIsError(false);
   // console.log("handleCaseDescriptionSubmit from APP.JS", formData);
    ReactGA.event({
      category: 'User',
      action: 'GenerateReport',
      label: 'Generate Report',
    });

    var formDataToSend = formData;
    var URL = `${server_url}/api/report/by_case_description?sendTo=${encodeURIComponent(formData.email)}`;

    if (formData.instructions != null && formData.instructions.length > 0) {

      const instructions = formData.instructions;
      const email = formData.email;
      const casedesc = formData;
      formDataToSend = {
        casedesc,
        email,
        instructions

      };

      URL = `${server_url}/api/report/by_case_description_perso`;


    }else{
      setIsStep2Empty(true);
      //wait for step 1 to complete.
      console.log("wait for step1 to return");
      return;
    }


      fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataToSend),
      })
        .then((response) => response.json())
        .then((data) => {
          var caseId = null;
          setIsLoading(false);
          if (data.overview != null) {
            caseId = data.overview.id;
            setOverview(data.overview);
           // console.log("data.overview", data.overview);
          }
          setPerso(data.perso);

       //   console.log("set perso", data.perso);
 
         // console.log("formData.instructions", formData.instructions);
          if (formData.instructions != null) {
            const newUrl = `/report/${caseId}`;
       //     console.log("push URL",newUrl);
            window.history.pushState({ caseId }, "", newUrl);
           
          }
          setCaseDescription(data.caseDescription);


   /* BACKUP


         if (isStep1Loading || (data != null && data.inProgress)) {
            // Call the function to initiate the fetch and retry logic
            fetchAndRetry(`${server_url}/api/report/${data.id}`, formData);
          } else {
            processOverviewResults(formData, data);
          }
*/
          
/*
          setIsLoading(false);
          if (data == null){
            console.log("Data is null - return.")
            return;
        }
          var caseId = null;
          // Set the ID in the URL
          //console.log("Method endpoint returned with ID:", caseId);
          if (formData.instructions == null || formData.instructions.length == 0) {
            console.log("set overview when no instruction "+data);
            caseId = data.id;            
            setOverview(data);
          } else {
            if (data.overview != null) {
              caseId = data.overview.id;
              setOverview(data.overview);
              console.log("data.overview", data.overview);
            }
            setPerso(data.perso);

            console.log("set perso", data.perso);
          }



          console.log("formData.instructions", formData.instructions);
          if (formData.instructions != null  ) {
            const newUrl = `/report/${caseId}`;
            window.history.pushState({ caseId }, "", newUrl);
          }
     setCaseDescription(data.caseDescription);
  */
        }) 
  .catch(error => {
    setIsLoading(false);
    setIsError(true);
    console.error('Error creating case:', error);
  });

   // console.log(`caseDescription condition: ${caseDescription.condition}, Stage: ${caseDescription.stage}, Agegroup: ${caseDescription.ageRange}`);
  };


  useEffect(() => {


  }, []);


  

  return (
    <Router>
      <div >   
   
    <div className="app-container">
    <div className="App-logo">
      <a href="/">  <img src={process.env.PUBLIC_URL + '/logo-with-icon-black-notagline.png'} alt="Logo" style={{    width: '350px',
    padding: '30px'
}} /></a>
      </div>
      {isLoading && (
        <p className='loading-message'>
          <FontAwesomeIcon icon={faSpinner} spin /> Generating your scientific overview report...
          <p>{loadingMessages[currentMessageIndex]}</p>
        </p>
      )}

{(!isLoading && isError)  && (
        <p className='error-message'>
      The server is overloaded, please try again later.
        </p>
      )}


{!isLoading  && (
        <Routes>
          <Route path="/" element={<PersonaliseDiseaseInfoForm onSubmit={handleCaseDescriptionSubmit} onStep1Submit={handleStep1Submit} onValidate={validateDisease} className="form-container" />} />
        
          <Route path="/report" element={overview.id !== '' ? <Report data={overview} perso={perso} /> : <Navigate to="/" />} />
          <Route path="/report/:reportId" element={<Report data={overview} perso={perso} />} />
          <Route path="/about" element={<AboutPage  className="form-container" />} />
          <Route path="/privacy" element={<Privacy  className="form-container" />} />
          <Route path="/browse" element={<BrowsePage  className="form-container" />} />
          {/* Add more routes for additional steps */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
 
 <footer>
      <p style={{color:'gray'}}>&copy; {new Date().getFullYear()} MedGPT360 • <a href="/privacy"  >Privacy Policy</a> • <a href="/browse"  >Browse</a></p>
    </footer>

    </div>
 
      </div>
    </Router>


 

  );
}
function ReportList(props) {
  return (
    <div>    {props.data ? (
      <ul>

        {props.data.map((item, index) => (
          <li key={index}>{item} <button>Select</button></li>
        ))}
      </ul>) : (
      <p>No report found.</p>
    )}
  </div>
  );
}
function ReportSection(props) {
  return (
    <div className="report-container">
      <h2>{props.title}</h2>
      <p>{props.intro}</p>
      {props.children}
    </div>
  );
}


function CaseDescription(props) {
  if (!props.data || props.data.mainCondition == null || props.data.mainCondition == '' ) {
    return null; // If props.data is null or undefined, return null to render nothing
  }
  
  return (   
    <div>
      
     <ul><li>Age Range: Adult</li>
     
     <li>{props.data.mainCondition}</li>
     <li>{props.data.stage}</li>
     </ul>
    </div>
  );
}

function KeyPoints(props) {

  if (!props  || ( props.keypoints_clinician == null &&  props.keypoints_patient ==null    )) {
    console.log("no data in props ",props);
    return null; // If props.data is null or undefined, return null to render nothing
  }
  
  return (
    <div>
      <h3>Key Points for Clinicians</h3>
      {props.keypoints_clinician ? (
        <ul>

          {props.keypoints_clinician.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>) : (
        <p>No key points for clinicians.</p>
      )}
      <h3>Key Points for Patients</h3>
      {props.keypoints_patient ? (
        <ul>
          {props.keypoints_patient.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>) : (<p>No key points for patients.</p>
      )}
    </div>

  );
}
function DiseaseInfo(props) {

  if (!props.data  ) {
    return null; // If props.data is null or undefined, return null to render nothing
  }
  
  return (
    <div className="report-section-container">

      <h3>Disease Category</h3>
      <p>{props.data.disease_category}</p>


      <h3>Prevalence</h3>
      <p>{props.data.prevalence}</p>

      <h3>Prognosis</h3>
      <p>{props.data.prognosis}</p>

      <h3>Diagnostics</h3>
      <p>{props.data.diagnostics}</p>

      <h4>Diagnostic work-up</h4>
      {props.data.diagnostic != null && props.data.diagnostic.diagnostic_workup ? (
        <ul>

          {props.data.diagnostic.diagnostic_workup.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>) : (
        <p>No data.</p>
      )}


      <h4>Staging</h4>
      { props.data.diagnostic != null && props.data.diagnostic ? (
      <p>{props.data.diagnostic.staging}</p>):(<p>No data.</p>)}

      <h4>Subtyping</h4>
      {props.data.diagnostic != null && props.data.diagnostic.subtyping ? (
        <ul>

          {props.data.diagnostic.subtyping.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>) : (
        <p>No data.</p>
      )}



      <h3>Treatment</h3>
      <p>{props.data.treatment}</p>

    </div>

  );
}


function BiomarkerTable(props) {
 
    return (
      <table>
        <thead>
          <tr>
            <th>Biomarker</th>
          {/*   <th>Result</th>*/}
            <th>Description</th>
            <th>Clinical significance</th>
           {/*  <th>ESCAT Actionability</th>*/}
            <th>Therapy</th>
           {/*  <th>Gene family</th>*/}
          {/*   <th>HGNC ID</th>*/}
            <th>Assay</th>
          {/*   <th>Prevalence</th>*/}
            <th>Note</th>
            <th>Controversies</th>
          </tr>
        </thead>
        <tbody>
          {props.data && Array.isArray(props.data)  ? props.data.map(row => (
            <tr key={row.id}>
              <td>{row.biomarker}</td>
          {/*    <td>{row.biomarker_result}</td> */}
              <td>{row.description}</td>
              <td>{row.clinical_significance}</td>
            {/*    <td>{row.actionability}</td>*/}
          {/*      <td>{row.therapy}</td>*/}
              <td>{row.treatment.join(", ")}</td>
             {/*   <td>{row.gene_family}</td>*/}
            {/*    <td>{row.HGNC_ID}</td>*/}
              <td>{row.assay}</td>
            {/*    <td>{row.prevalence}</td>*/}
              <td>{row.common_note}</td>
              <td>{row.controversies}</td>
            </tr>
          )) : null}
        </tbody>
      </table>
    );
  }

  

function TreatmentTable(props) {
  
    return (
      <table>
        <thead>
          <tr>
            <th>Line of therapy</th>
            <th>Treatment</th>
            <th>Category</th>
            <th>Drug class</th>
            <th>Description</th>
            <th>Rationale</th>
            <th>FDA Approval status</th>
            <th>EMA Approval status</th>
            <th>Common note</th>
           
          </tr>
        </thead>
        <tbody>
          {props.data && Array.isArray(props.data)  ? props.data.map(row => (
            <tr key={row.id}>
               <td>{row.line_of_therapy}</td>
              <td>{row.name}</td>
              <td>{row.category}</td>
              <td>{row.drug_class}</td>
              <td>{row.description}</td>
              <td>{row.rationale}</td>
              <td>{row.fda_approval_status}</td>
              <td>{row.ema_approval_status}</td>
              <td>{row.common_note}</td>
          
            </tr>
          )) : null}
        </tbody>
      </table>
    );
  }



  
function LifestyleTable(props) {
 
    return (
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Lifestyle</th>
            <th>rationale</th>
            <th>evidence_level</th>
            <th>accessibility</th>
           
           
          </tr>
        </thead>
        <tbody>
          {props.data && Array.isArray(props.data)  ? props.data.map(row => (
            <tr key={row.id}>
              <td>{row.type}</td>
              <td>{row.lifestyle}</td>
              <td>{row.rationale}</td>
              <td>{row.evidence_level}</td>
              <td>{row.accessibility}</td>
             
            </tr>
          )) : null}
        </tbody>
      </table>
    );
  }
  function ExpertTable(props) {
   
      return (
        <table>
          <thead>
            <tr>
              <th>Firstname</th>
              <th>Lastname</th>
              <th>credentials</th>
              <th>organisation</th>
              <th>country</th>
              <th>note</th>
             
            </tr>
          </thead>
          <tbody>
            {props.data && Array.isArray(props.data)  ? props.data.map(row => (
              <tr key={row.id}>
                <td>{row.firstname}</td>
                <td>{row.lastname}</td>
                <td>{row.credentials}</td>
                <td>{row.organisation_name}</td>
                <td>{row.country}</td>
                <td>{row.common_note}</td>
               
              </tr>
            )) : null}
          </tbody>
        </table>
      );
    }
  

function Table(props) {
//console.log(props);
  return (
    <table>
      <thead>
        <tr>
          <th>Column 1</th>
          <th>Column 2</th>
        </tr>
      </thead>
      <tbody>
        {props.data ? props.data.map(row => (
          <tr key={row.id}>
            <td>{row.column1}</td>
            <td>{row.column2}</td>
          </tr>
        )) : null}
      </tbody>
    </table>
  );
}


export default App;
