import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';


function FDALabel({ data }) {
    const [showPopup, setShowPopup] = useState(false);
    
  
    const handleButtonClick = () => {
      setShowPopup(true);
    };
  
    const handleClose = () => {
      setShowPopup(false);
    };
  
  
  
    return (
      <div>
 
<Button
       
       variant="secondary" title="Show drug label" onClick={handleButtonClick}>  Show Label  </Button>
    


 <Modal dialogClassName="reference-modal" show={showPopup} onHide={handleClose} size="lg" >
        <Modal.Header closeButton>
          <Modal.Title>FDA Label {data.drugName}</Modal.Title>
        </Modal.Header>
        <Modal.Body  style={{ maxHeight: '78vh', overflowY: 'auto' }}>
        <p> Effective Date: {data.effectiveDateRaw}</p>
        <div dangerouslySetInnerHTML={{ __html: data.indication }} />;
          
       <p>{data.specialPopulation}</p>
       <p>{data.warnings}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
 </div>
    );
    } 
    export default FDALabel;