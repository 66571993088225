import React from 'react';
import Roadmap from './Roadmap'; 
import FeedbackForm from './FeedbackForm';
import ContactForm from './ContactForm';

function AboutPage() {

  document.title = 'About MedGPT360';

  return (
    <div className="about-container">
      <h2>About MedGPT360</h2>
      <p>Welcome to MedGPT360!</p>
      <p>
      Scientific overviews play a vital role in delivering accurate and accessible information, promoting understanding, and supporting evidence-based decision making. They serve as a bridge between scientific knowledge and the public, keeping individuals informed about the latest research and advancements while encouraging critical thinking.
      </p>
      <p>
      MedGPT360 is an AI-powered website that empowers you to generate comprehensive 360 reports for any disease. With MedGPT360, you can access detailed text and tables that delve into the disease's background, diagnosis, prevalence, biomarkers clinical significance, treatment options, preventive measures, as well as insights on experts and ongoing research.
      
      </p>
      <p>
      Generating such comprehensive reports necessitates a deep understanding of medical literature and expertise in synthesizing and presenting information effectively. The process also requires continuous updates to incorporate new research findings and advancements in the field. While AI models like GPT automate the report generation process to a great extent, it is essential to validate the content with human experts, ensuring its alignment with current medical knowledge and guidelines.


            

      </p>

      <p>      <span className="leading-text">MedGPT360 learns from the collective feedback loop from users like you. <br></br></span>Your feedback is instrumental in improving the accuracy. We value your input and would love to hear your thoughts on the reports. Help improve their accuracy by sharing your feedback.</p>
      <hr className="divider" />
      <p className="cta-sentence ">Together, let's advance the field of medical knowledge dissemination and empower individuals with reliable and up-to-date insights!</p>
      <hr className="divider" />
      <h2>Contact us</h2>
<ContactForm></ContactForm>
<hr className="divider" />

 

</div>
  );
}

export default AboutPage;
