import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReferenceList from './ReferenceList';
import { FaBook } from 'react-icons/fa';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { IoGlobeOutline } from 'react-icons/io5';

function ReferencePopupButton({ data , label}) {
  const [showPopup, setShowPopup] = useState(false);
  

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };



  return (
    <div>
 


      <Button
      style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
       variant="secondary" title="Show online references" onClick={handleButtonClick}>   <FaBook color="#808080" size={18} onMouseOver={(e) => e.target.style.color = '#0056b3'} onMouseOut={(e) => e.target.style.color = '#808080'} />   </Button>
    
    
      <Modal dialogClassName="reference-modal" show={showPopup} onHide={handleClose} size="lg" >
        <Modal.Header closeButton>
          <Modal.Title>{data[0].reportName} References</Modal.Title>
        </Modal.Header>
        <Modal.Body  style={{ maxHeight: '78vh', overflowY: 'auto' }}>
          {/* Render the row-specific content here */}
      <ReferenceList data={data} showTagsButton={false}></ReferenceList>
          {/* Add more content specific to each row */}
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReferencePopupButton;
