import React, { useState , useEffect  } from 'react';
import { BrowserRouter as   Link   } from 'react-router-dom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import FeedbackForm from './FeedbackForm';
import config from '../config';
import ShareButton from './ShareButton';
import NewReportButtonNoText from './NewReportButtonNoText';
import StreamedText from './StreamedText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner , faCircle,faMagic, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import ReferenceList from './ReferenceList';
import ReferencePopupButton from './ReferencePopupButton';
import ReactGA from 'react-ga4';
import { Table, Button, Modal } from 'react-bootstrap';
import NavigationMenu from './NavigationMenu';
import FDALabel from './FDALabel';
import { faArrowsSpin } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faHatWizard ,faSync } from '@fortawesome/free-solid-svg-icons';
 
import AIObservationModal from './AIObservationModal';
import LiveUpdatesModal from './LiveUpdatesModal';
import { faExclamationCircle, faTrash, faExclamationTriangle, faBan } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import MedicationPopupButton from './MedicationPopupButton';

const Report = ({ data, perso }) => {
  const [reportData, setReportData] = useState( {generalInfo: {diagnostic: {}}, standardBiomarker:  [], standardRx:  [], lifestyle:  [], expert:  []});
    const [isLoading, setIsLoading] = useState(true);
    const [isDifferentialLoading, setIsDifferentialLoading] = useState(false);
    const [diseaseInfo, setDiseaseInfo] = useState({ intro: '', table1: [], table2: [] });
    const [biomarkersStd, setBiomarkersStd] = useState({ intro: '', table1: [], table2: [] });
    const [biomarkersEmerging, setBiomarkersEmerging] = useState({ intro: '', table1: [], table2: [] });
    const [treatmentsStd, setTreatmentsStd] = useState({ intro: '', table1: [], table2: [] });
    const [treatmentsEmerging, setTreatmentsEmerging] = useState({ intro: '', table1: [], table2: [] });
    const [lifestyle, setLifestyle] = useState({ intro: '', table1: [], table2: [] });
    const [experts, setExperts] = useState({ intro: '', table1: [], table2: [] });
    const [aimodel, setAimodel] = useState({name: '', version:'', description:''});
    const [isDiseaseInfoVisible, setDiseaseInfoVisible] = useState(true);
    const [isPersoPageVisible, setPersoPageVisible] = useState(perso!=null);
    const [isBiomarkersInfoVisible, setBiomarkersInfoVisible] = useState(false);
 
    const [isRxInfoVisible, setRxInfoVisible] = useState(false);
    const [isLifestyleInfoVisible, setLifestyleInfoVisible] = useState(false);
    const [isExpertInfoVisible, setExpertsInfoVisible] = useState(false);
    const [isReferencesInfoVisible, setReferencesInfoVisible] = useState(false);

    const [feedback, setFeedback] = useState({accuracy: '', helpfulness:'', note:''});
    const [isFeedbackSaved, setIsFeedbackSaved] = useState(false);
    
    const { reportId } = useParams();

    const [isBiomarkerLoading, setIsBiomarkerLoading] = useState(false);
    const [isTreatmentLoading, setIsTreatmentLoading] = useState(false);
    const [isLifestyleLoading, setIsLifestyleLoading] = useState(false);
    const [isExpertLoading, setIsExpertLoading] = useState(false);
    const [isReferenceLoading, setIsReferenceLoading] = useState(false);
    const [isDiagnosisWorkupLoading, setIsDiagnosisWorkupLoading] = useState(false);
 
    const server_url = config.server_url;
  
    const [showSymptoms, setShowSymptoms] = useState(false);
    const [showDifferentialDiagnosis, setShowDifferentialDiagnosis] = useState(false);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [updatesModalIsOpen, setUpdatesModalIsOpen] = useState(false);
    const [formattedDate , setFormattedDate ] = useState('');

    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('diseaseInfo'); // Default tab
    const [persoPage, setPersoPage] = useState({});
   

  // Parse the tab parameter from the URL on component mount
  useEffect(() => {
    //console.log("check tab param")
    const tabParam = new URLSearchParams(location.search).get('tab');

    if (tabParam) {
      setActiveTab(tabParam);
       
    }
    

    const fetchPersoPage = async (reportId, persoParam) => {
      const response = await fetch(`${server_url}/api/report/${reportId}/perso/${persoParam}/persopage`);
      const rData = await response.json();

      setPersoPage(rData);
    
      //console.log('fetched perso data from server');
    };



    const persoParam = new URLSearchParams(location.search).get('perso');
    if (persoParam) {
     
     // console.log("persoParam: ", persoParam);

      if (reportId!=null && ( persoPage == null || persoPage == {} || persoPage.id == null)) {
        fetchPersoPage(reportId, persoParam);
      }

     

    }


  }, [location]);


    useEffect(() => {
      // Update the URL with the current tab
 
    
     // console.log("navigate: ", `?tab=${activeTab}`);
      if (activeTab) {
        //console.log("init active tab", activeTab);
       // setActiveTab(activeTab);
        initializeProps();
      }  

    }, [activeTab]);



     // Function to initialize props based on the active tab
  const initializeProps = () => {

  
    if (activeTab === 'persoPage') {
     // console.log("activate perso page");
      setPersoPageVisible(true);
      setDiseaseInfoVisible(false);
      setBiomarkersInfoVisible(false);
      setRxInfoVisible(false);
      setLifestyleInfoVisible(false);
      setExpertsInfoVisible(false);
      setReferencesInfoVisible(false);
    } 

   else if (activeTab === 'diseaseInfo') {
      setPersoPageVisible(false);
      setDiseaseInfoVisible(true);
      setBiomarkersInfoVisible(false);
      setRxInfoVisible(false);
      setLifestyleInfoVisible(false);
      setExpertsInfoVisible(false);
      setReferencesInfoVisible(false);
    } else if (activeTab === 'biomarkers') {
      setPersoPageVisible(false);
      setDiseaseInfoVisible(false);
      setBiomarkersInfoVisible(true);
      setRxInfoVisible(false);
      setLifestyleInfoVisible(false);
      setExpertsInfoVisible(false);
      setReferencesInfoVisible(false);
    }
    else if (activeTab === 'treatments') {
      setPersoPageVisible(false);
      setDiseaseInfoVisible(false);
      setBiomarkersInfoVisible(false);
      setRxInfoVisible(true);
      setLifestyleInfoVisible(false);
      setExpertsInfoVisible(false);
      setReferencesInfoVisible(false);
    }
    else if (activeTab === 'lifestyles') {
      setPersoPageVisible(false);
      setDiseaseInfoVisible(false);
      setBiomarkersInfoVisible(false);
      setRxInfoVisible(false);
      setLifestyleInfoVisible(true);
      setExpertsInfoVisible(false);
      setReferencesInfoVisible(false);
    }
    else if (activeTab === 'experts') {
      setPersoPageVisible(false);
      setDiseaseInfoVisible(false);
      setBiomarkersInfoVisible(false);
      setRxInfoVisible(false);
      setLifestyleInfoVisible(false);
      setExpertsInfoVisible(true);
      setReferencesInfoVisible(false);
    }
    else if (activeTab === 'references') {
      setPersoPageVisible(false);
      setDiseaseInfoVisible(false);
      setBiomarkersInfoVisible(false);
      setRxInfoVisible(false);
      setLifestyleInfoVisible(false);
      setExpertsInfoVisible(false);
      setReferencesInfoVisible(true);
    }
    else if (activeTab === 'sendFeedback') {
      setPersoPageVisible(false);
      setDiseaseInfoVisible(false);
      setBiomarkersInfoVisible(false);
      setRxInfoVisible(false);
      setLifestyleInfoVisible(false);
      setExpertsInfoVisible(false);
      setReferencesInfoVisible(false);
    }
    else {
      console.log("not recognosed")
      setPersoPageVisible(false);
      // if not recognised, show diseaseinfo
      setDiseaseInfoVisible(true);
      setBiomarkersInfoVisible(false);
      setRxInfoVisible(false);
      setLifestyleInfoVisible(false);
      setExpertsInfoVisible(false);
      setReferencesInfoVisible(false);
    }
  };

 
    const handleTabChange = (newTab) => {
       
      setActiveTab(newTab);
   //   navigate(`?tab=${newTab}`, { replace: true })

       // Get the current query parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Update or add the new tab parameter
    queryParams.set('tab', newTab);

    // Use navigate to update the URL without changing the path
    navigate(`?${queryParams.toString()}`, { replace: true });

 
    };


    const  handleCheckUpdate =async   (email) => {

      console.log("in handleCheckUpdate")

       // Encode the email address to include it in the URL
  const encodedEmail = encodeURIComponent(email);



      const response = await  fetch(`${server_url}/api/report/${reportId}/check_updates?email=${encodedEmail}`); // Replace with your backend API endpoint
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const newTreatments = await   response.json();
return newTreatments;
      //show treatemtn in modal

      /*
      const rData = await   response.json();

      // Update the state with the fetched   data
      await   setReportData(prevData => ({
        ...prevData,
        generalInfo: {
          ...prevData.generalInfo,
          diagnostic: rData.generalInfo.diagnostic
        }
      }));*/
    }


    const openModal = () => {

      ReactGA.event({
        category: 'User',
        action: 'AIObservations',
        label: 'AIObservations',
      });


      setModalIsOpen(true);
    };
  
    const closeModal = () => {
      setModalIsOpen(false);
    };
    const openUpdatesModal = () => {

      
      ReactGA.event({
        category: 'User',
        action: 'CheckUpdates',
        label: 'CheckUpdates',
      });



      setUpdatesModalIsOpen(true);
    };
  
    const closeUpdatesModal = () => {
      setUpdatesModalIsOpen(false);
    };
    
    const TOGGLE_ON_EXPERTS =false;

    useEffect(() => {
   //   console.log("in report page useEffect data ", reportId);
    }, [data]);

    useEffect(() => {
    
      if (reportData != null && reportData.reportName != null) {
        document.title = isLoading ? '⏳ '+reportData.reportName+'...' : reportData.reportName;  
      }

    }, [isLoading]);


 


  useEffect(() => {
 
  
  //  console.log("in report page useEffect reportId ", reportId);
    ReactGA.send({ hitType: "pageview", page: "/report/"+reportId, title: "R"+reportId});

    const fetchReportData = async () => {
      try {
         setIsLoading(true);
        const response = await fetch(`${server_url}/api/report/${reportId}`); // Replace with your backend API endpoint
        const rData = await response.json();
        await setReportData(rData);
       // console.log("rData after fetch" , rData);
        if (rData.inProgress) {
          console.log("report still building");
          return;
        }
        if (rData != null && rData.reportName != null) {     
          document.title = rData.reportName ;  
        }
        if (!rData.inProgress && rData.generalInfo == null) {
          await fetchGeneralInfo(reportId);
        }
        if (!rData.inProgress && (rData.generalInfo.diagnostic == null  || ( rData.generalInfo.diagnostic.length == 0 && !isDiagnosisWorkupLoading && !isTreatmentLoading && !isBiomarkerLoading) )) {
          await fetchDiagnosticWorkup(reportId);
        }


        if (rData.standardBiomarker == null   || ( rData.standardBiomarker.length == 0 && !isDiagnosisWorkupLoading && !isTreatmentLoading && !isBiomarkerLoading)) {
          await fetchBiomarkers(reportId);
        }

        if (rData.standardRx == null   || ( rData.standardRx.length == 0 && !isTreatmentLoading && !isBiomarkerLoading) ) {
          await fetchTreatments(reportId);
        }
        if (rData.lifestyle == null  || ( rData.lifestyle.length == 0  && !isTreatmentLoading && !isBiomarkerLoading && !isLifestyleLoading) ) {
          //here, it might not be null but just empty if the user closed the browser before the full report was generated
          await fetchLifestyle(reportId);
        }
        if (rData.generalInfo.differential == null  ) {
          await fetchDifferential(reportId);
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching report data:', error);
      }
    }
   
    const fetchGeneralInfo = async (reportId) => {
      try {
      //  console.log('calling  fetchGeneralInfo ' );
        const response = await fetch(`${server_url}/api/generalinfo/${reportId}`); // Replace with your backend API endpoint
        const rData = await response.json();
 
        // Update the state with the fetched   data
        await setReportData(prevData => ({
          ...prevData,
          generalInfo: { 
            diagnostics: rData.diagnostics,
            disease_category: rData.disease_category,
            disease_description: rData.disease_description,
            prevalence: rData.prevalence,
            prognosis: rData.prognosis,
            symptoms: rData.symptoms,
            treatment: rData.treatment
            }
        }));
       
        if (data.diagnostic == null) {
        fetchDiagnosticWorkup(reportId);
        }

 
      } catch (error) {
 
        console.error('Error fetchDiseaseInfo:', error);
      }
    }
    
    const fetchDiagnosticWorkup = async (reportId) => {
      try {
        setIsDiagnosisWorkupLoading(true);
        const response = await fetch(`${server_url}/api/diagnostic/${reportId}`); // Replace with your backend API endpoint
        const rData = await response.json();

        // Update the state with the fetched   data
       await setReportData(prevData => ({
          ...prevData,
          generalInfo: {
            ...prevData.generalInfo,
            diagnostic: rData.generalInfo.diagnostic
          }
        }));
        setIsDiagnosisWorkupLoading(false);
      //  console.log("fetchDiagnosticWorkup set");
        if (data.biomarkersStd == null || (data.biomarkersStd.length==0 && !isBiomarkerLoading)) {
          fetchBiomarkers(reportId);
        }

      } catch (error) {
        setIsDiagnosisWorkupLoading(false);
        console.error('Error fetchDiagnosticWorkup:', error);
      }
    };

    const fetchDifferential = async (reportId) => {
      try {
        setIsDifferentialLoading(true);
        const response = await fetch(`${server_url}/api/differential/${reportId}`);
        const rData = await response.json();

        // Update the state with the fetched   data
        await setReportData(prevData => ({
          ...prevData,
          generalInfo: {
            ...prevData.generalInfo,
            differential: rData.generalInfo.differential
          }
        }));
        setIsDifferentialLoading(false);


      } catch (error) {
        setIsDifferentialLoading(false);
        console.error('Error fetchDifferential:', error);
      }
    };


    const fetchBiomarkers = async (reportId) => {
      try {
        setIsBiomarkerLoading(true);
        const response = await fetch(`${server_url}/api/biomarkers/${reportId}`); // Replace with your backend API endpoint
        const rData = await response.json();

        // Update the state with the fetched biomarker data
        await setReportData(prevData => ({
          ...prevData,
          standardBiomarker: rData.standardBiomarker,
          emergingBiomarker: rData.emergingBiomarker
        }));
        setIsBiomarkerLoading(false);
        //  console.log("fetchBiomarkers set");
        if (data.standardRx == null   || ( rData.standardRx.length == 0 && !isTreatmentLoading  ) ) {
          fetchTreatments(reportId);
        }

      } catch (error) {
        setIsBiomarkerLoading(false);
        console.error('Error fetchBiomarkers:', error);
      }
    };


    const fetchTreatments = async (reportId) => {
      try {
        setIsTreatmentLoading(true);
        const response = await fetch(`${server_url}/api/treatments/${reportId}`); // Replace with your backend API endpoint
        const rData = await response.json();

        // Update the state with the fetched biomarker data
        await  setReportData(prevData => ({
          ...prevData,
          standardRx: rData.standardRx,
          emergingRx: rData.emergingRx
        }));
        setIsTreatmentLoading(false);
        
       // console.log("standardRx set");
        if (data.lifestyle == null || (data.lifestyle.length==0 && !isLifestyleLoading) ) {
          fetchLifestyle(reportId);
        }
      } catch (error) {
        setIsTreatmentLoading(false);
        console.error('Error standardRx:', error);
      }
    };

    const checkFDA = (reportId) => {
    
      fetch(`${server_url}/api/fdadruglabel/${reportId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(rData => {
        setReportData(prevData => ({
          ...prevData,
          standardRx: rData.standardRx,
          emergingRx: rData.emergingRx
        }));
       
    });
  }

    const fetchLifestyle = async (reportId) => {
      try {
        setIsLifestyleLoading(true);
        const response = await fetch(`${server_url}/api/lifestyle/${reportId}`); // Replace with your backend API endpoint
        const rData = await response.json();

        // Update the state with the fetched   data
       await setReportData(prevData => ({
          ...prevData,
          lifestyle: rData
        }));
        setIsLifestyleLoading(false);
      //  console.log("fetchLifestyle set");
        if (data.expert == null && TOGGLE_ON_EXPERTS) {
          fetchExpert(reportId);
        }

        else if(data.generalInfo !=null && (data.generalInfo.differential == null|| data.generalInfo.differential.length ==0)){
        console.log("differential fetched from lifestyle");
         await fetchDifferential(reportId);
         setIsLoading(false);//assuming everyting is logged.
        }

      } catch (error) {
        setIsLifestyleLoading(false);
        console.error('Error setIsLifestyleLoading:', error);
      }
    };

    const fetchExpert = async (reportId) => {
      try {
        setIsExpertLoading(true);
        const response = await fetch(`${server_url}/api/expert/${reportId}`); // Replace with your backend API endpoint
        const rData = await response.json();

        // Update the state with the fetched   data
       await setReportData(prevData => ({
          ...prevData,
          expert: rData
        }));
        setIsExpertLoading(false);
        //console.log("setIsExpertLoading set");
        

      } catch (error) {
        setIsExpertLoading(false);
        console.error('Error setIsExpertLoading:', error);
      }
    };






    if (!data || data.id === '') {
      // Fetch the report data if prop data is not provided or id is empty
      //console.log("report.  fetchReportData");
       fetchReportData();
 
    } else {
     //console.log("setReportData  :",data.reportName );
      ReactGA.send({ hitType: "pageview", page: "/report/" + reportId, title: "Report " + data.reportName });
      // Use the prop data if available
         setReportData(data);
         setPersoPage(perso);

      // Use navigate to update the URL without triggering a full page reload


      if (perso != null) {
        setActiveTab("persoPage");
      }
       
             // Get the current query parameters
    const queryParams = new URLSearchParams(window.location.search);

      // Update or add the new parameters
      if (perso!=null && perso.id != null) {
        queryParams.set('perso', perso.id);
         
      } 
    queryParams.set('tab', activeTab);

    // Combine the current path with the updated query parameters
    const newPath = `/report/${data.id}?${queryParams.toString()}`;

    // Use navigate to update the URL without triggering a full page reload
    navigate(newPath, { replace: true });

    if (!data.inProgress && data.generalInfo == null ) {
    //  console.log("call fetch general info");
      fetchGeneralInfo(data.id);
    }
   
      if (!data.inProgress && data.generalInfo != null && data.generalInfo.diagnostic == null) {
       // console.log("call fetchDiagnosticWorkup");
        fetchDiagnosticWorkup(data.id);
      }

      if (data.generalInfo != null
        && data.emergingBiomarker != null && data.emergingBiomarker.length > 0
        && data.standardBiomarker != null && data.standardBiomarker.length > 0
        && data.lifestyle != null && data.lifestyle.length > 0
        && data.emergingRx != null && data.emergingRx.length > 0
        && data.standardRx != null && data.standardRx.length > 0
      ) {
        setIsLoading(false);
      }
 
    }
 

  }, [  reportId]);
 // Use useEffect to respond to changes in the perso prop
  useEffect(() => {
    // Do something when perso changes
   // console.log('Perso has changed:', perso);
    if (perso != null) {
      setPersoPage(perso);

      // Get the current query parameters
      const queryParams = new URLSearchParams(window.location.search);

      // Update or add the new parameters
      if (perso != null && perso.id != null) {
        queryParams.set('perso', perso.id);

      }
      queryParams.set('tab', activeTab);

      // Combine the current path with the updated query parameters
      const newPath = `/report/${data.id}?${queryParams.toString()}`;

      // Use navigate to update the URL without triggering a full page reload
      navigate(newPath, { replace: true });
    }
  }, [perso]); // This effect will run whenever perso changes


  useEffect(() => {
    // Check if reportData.modifiedDate is available and format it
    if (reportData.modifiedDate) {
      const date = new Date(reportData.modifiedDate);
      const year = date.getFullYear();
      const month = date.toLocaleString('default', { month: 'short' });
      const day = date.getDate();
      const formattedDate = `${year}-${month}-${day}`;
      setFormattedDate(formattedDate);
     
    }
  }, [reportData.modifiedDate]);


  const handleAIObersationsClick = async (email) => {
    try {
      //console.log('clicked handleAIObersationsClick');
      setIsLoading(true);
      const response = await fetch(`${server_url}/api/report/${reportData.id}/observations?sendTo=${encodeURIComponent(email)}`);  
      const rData = await response.json();

      // Update the state with the fetched biomarker data
      setReportData(  rData  );
      setIsLoading(false);
  
  
    } catch (error) {
      setIsLoading(false);
      console.error('Error handleRenerateClick:', error);
    }
  };



  const handleRenerateClick = async () => {
    try {
      console.log('clicked handleRenerateClick');
      setIsLoading(true);
      const response = await fetch(`${server_url}/api/report/${reportData.id}/regenerate`); // Replace with your backend API endpoint
      const rData = await response.json();

      // Update the state with the fetched biomarker data
      setReportData(  rData  );
      setIsLoading(false);
  
  
    } catch (error) {
      setIsLoading(false);
      console.error('Error handleRenerateClick:', error);
    }
  };

   
    const handleFeedbackSubmit = (formData) => {
  //console.log(formData, reportData);
      feedback.accuracy = formData.accuracy;
      feedback.helpfulness = formData.helpfulness;
      feedback.note = formData.note ;
      feedback.email = formData.email;
      feedback.scientificOverviewId = reportData.id;
      feedback.pageWhenReporting = window.location.pathname +getSelectedSection();
      feedback.reportWhenReporting = reportData.reportName;
  
      fetch(`${server_url}/api/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(feedback)
      })
      .then(response => response.json())
      .then(data => {
       // console.log("feedback saved", data);
          });
        setIsFeedbackSaved(true);

        
      ReactGA.event({
        category: 'User',
        action: 'Clicked Button',
        label: 'SendFeedback',
      });

    };
  

  function ReportSection(props) {
    return (
      <div >
        report section
        <h2>{props.title}</h2>
        <p>{props.intro}</p>
        {props.children}
      </div>
    );
  }

  const handleDifferentialLinkClick = (event, preferredName) => {
    event.preventDefault(); // Prevent the default behavior of the link
    // Call your React function here, passing preferredName or any other data you need
    console.log(`Link clicked for ${preferredName}`);
    setIsDifferentialLoading(true);
    ReactGA.event({
      category: 'User',
      action: 'Clicked Button',
      label: 'Differential Diagnosis',
    });
    toast.success(`Will open the "${preferredName}" report in a new tab once ready. Please authorise popups in your browser if needed.`, { position: toast.POSITION.TOP_RIGHT , autoClose: 50000});
    console.log("differential " );
    const formData = {
      mainCondition: preferredName,
      stage: reportData.caseDescription.stage,
      ageRange: reportData.caseDescription.ageRange,
    };
    fetch(`${server_url}/api/report/by_case_description`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
        if (data == null) {
          console.log("Data is null - return.")
          return;
        }
        setIsDifferentialLoading(false);

        const caseId = data.id;
        // Set the ID in the URL
        //console.log("Method endpoint returned with ID:", caseId);
       
        const newUrl = `/report/${caseId}`;
        
        window.open(newUrl, "_blank");

        console.log("differential new URL: ",newUrl );
       
      });
  }

  function CaseDescription(props) {
    if (!props.data || props.data.mainCondition == null || props.data.mainCondition == '' ) {
      return null; // If props.data is null or undefined, return null to render nothing
    }
    
    return (   
      <span>
         {props.data.ageRange} {props.data.mainCondition}     {props.data.stage}  
       
      </span>
    );
  }

  function CaseDescriptionBAK(props) {
    if (!props.data || props.data.mainCondition == null || props.data.mainCondition == '' ) {
      return null; // If props.data is null or undefined, return null to render nothing
    }
    
    return (   
      <div>
        
       <ul>
        <li> {props.data.ageRange}</li>
       
       <li>{props.data.mainCondition}</li>
       {props.data.stage && <li>{props.data.stage}</li>}
       </ul>
      </div>
    );
  }
  
  
  function KeyPoints(props) {
  
    if (!props  || ( props.keypoints_clinician == null &&  props.keypoints_patient ==null    )) {
      console.log("no data in props ",props);
      return null; // If props.data is null or undefined, return null to render nothing
    }
    
    return (
      <div>
        <h4>Key Points for Clinicians</h4>
        {props.keypoints_clinician ? (
          <ul style={{backgroundColor:'lightblue'}}>
  
            {props.keypoints_clinician.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>) : (
          <p>No key points for clinicians.</p>
        )}
        <h4>Key Points for Patients</h4>
        {props.keypoints_patient ? (
          <ul>
            {props.keypoints_patient.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>) : (<p>No key points for patients.</p>
        )}
      </div>
  
    );
  }


  function PersoPage(props) {
    function escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    
     // Function to create a popover for a term
  const renderPopover = (term, definition) => (
    <Popover id={`${term}-popover`}>
    <Popover.Header as="h3">{term}</Popover.Header>
    <Popover.Body>{definition}</Popover.Body>
  </Popover>
  );

  
    if (!props  || props.data==null || ( props.data!=null && props.data.actionable_insights == null   )) {
      console.log("no data in perso props ",props);
return null;
 
    }
    
    return (
      <div className="section-container ">
             
             <div className="d-md-none">
             <h3>Actionable Insights</h3></div>

             <div className="d-none d-md-block"> 
        <h3>Personalised Actionable Insights</h3></div>
        <p className='actionable-insight-intro'>{props.data.summary}</p>
        {props.data.actionable_insights ? (
                <ul className='actionable-insight' style={{backgroundColor:'lightblue', paddingTop: '15px', paddingLeft: '5px' ,paddingBottom: '15px' }}>
                {props.data.actionable_insights.map((insight, index) => (
                  <ol key={index}>
                    <h5>{insight.title}</h5>
                    {
  insight.medications !== null && insight.medications.length > 0 &&
  insight.medications.map((medication, index) => (
    <MedicationPopupButton
      key={index}
      data={medication}

      label={`${medication.medication_class} (${medication.brand_names.join(', ').substring(0, 30)}${medication.brand_names.join(', ').length > 30 ? '...' : ''})`}
    
     
    />
  ))
}
                 
                    <p>
                      
                    {insight.description.split(' ').map((word, i) => {

                       // Skip the check for the word "and"
    if (word.toLowerCase() === "and") {
      return <span key={i}>{word} </span>;
    }
    const matchingMedication = insight.medications.find(medication => {
      console.log("evaluate", medication,"'"+ word.toLowerCase()+"'", medication.medication_class.toLowerCase() === word.toLowerCase());
      return (
        medication.brand_names.includes(word.toLowerCase()) ||
        medication.generic_name.toLowerCase() === word.toLowerCase() ||
        medication.medication_class.toLowerCase() === word.toLowerCase()
      );
    });
    console.log("matchingMedication",matchingMedication);
    if(matchingMedication){
      console.log("matchingMedication",matchingMedication);

      return (
        // The child element triggering the overlay
        <OverlayTrigger
          key={i}
          placement="top"
          overlay={renderPopover(matchingMedication.medication_class, matchingMedication.evidence)}
        >
       <span>   <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>{word}</span>{' '}</span>
        </OverlayTrigger>
      );
    }
              if (props.data.definitions) {
                const matchingDefinition = props.data.definitions.find(
                  (def) => {
                    const escapedWord = escapeRegExp(word.toLowerCase()).replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]+$/, '');
                  //  const escapedWord = escapeRegExp(word.toLowerCase()).replace(/,/g, '');
                    const regex = new RegExp(`\\b${escapedWord}\\b`, 'g');
                  //  console.log(`Searching for ${escapedWord} in ${def.term.toLowerCase()}`);

                    return regex.test(def.term.toLowerCase());
                  }
                );

                if (matchingDefinition) {
                  return (
                    // The child element triggering the overlay
                    <OverlayTrigger
                      key={i}
                      placement="top"
                      overlay={renderPopover(matchingDefinition.term, matchingDefinition.definition)}
                    >
                   <span>   <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>{word}</span>{' '}</span>
                    </OverlayTrigger>
                  );
                } else {
                  return <span key={i}>{word} </span>;
                }
              } else {
                return <span key={i}>{word} </span>;
              }
            })}



{
(insight.references!=null && insight.references.length>0) 
&& (  <ReferencePopupButton style={{ display: "inline-block  !important" }} data={insight.references} label="open references"></ReferencePopupButton>
 
 )}



          </p>


                  </ol>
                ))}
              </ul>) : (
          <p>No key points for clinicians.</p>
        )}
 
 <p>Go to next: <a href="#" onClick={() => {
          handleTabChange('diseaseInfo');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}   >Disease Info</a></p>
      </div>
  
    );
  }


  function DiseaseInfo(props) {
  
   // console.log("props ",props);
    if (!props.data  ) {
      return null; // If props.data is null or undefined, return null to render nothing
    }
    
    return (
      <div className="section-container ">
  <h3>Disease Information</h3>

        <h4>Disease Category</h4>
        <p>{props.data.disease_category}</p>
  
  
        <h4>Prevalence</h4>
        <p>{props.data.prevalence} </p>
  
        <h4>Prognosis</h4>
        <p>{props.data.prognosis}</p>
        
  
        <h4>Diagnostics</h4>
        <p>{props.data.diagnostics}</p>

        {!( isDiagnosisWorkupLoading || isBiomarkerLoading || isTreatmentLoading || isLifestyleLoading || isLoading) && (    <div   style={{ marginTop: "-8px", marginBottom:"20px"}} >
        {( (props.data.symptoms != null && props.data.symptoms.length > 0 ) &&  <button  className={`button  ${showSymptoms ? 'statuson' : ''}`} style={{marginRight: "10px", borderRadius:"5px" }} onClick={() => setShowSymptoms(!showSymptoms)}>Symptoms</button>)}
        {( (props.data.differential != null && props.data.differential.length > 0 ) &&   <button className={`button ${showDifferentialDiagnosis ? 'statuson' : ''}`} style={{marginRight: "10px", borderRadius:"5px"}}  onClick={() => setShowDifferentialDiagnosis(!showDifferentialDiagnosis)}>Differential Diagnosis</button>)}

      {showSymptoms && (
        <div>
          <h4>Symptoms upon disease onset</h4>
          {props.data.symptoms != null && props.data.symptoms.length > 0 ? (
            <ul className="list-paragraph">
              {props.data.symptoms.map((item, index) => (
                <li className="list-item" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            <p>No data yet.</p>
          )}


        </div>
  )}
  
{showDifferentialDiagnosis && (
  <div>
    <h4>Differential Diagnosis   {isDifferentialLoading ? ( <span> <FontAwesomeIcon icon={faSpinner} spin title="Fetching report. Will open in new tab (you may need to authorise it from your Browser title bar), this can take a minutes..."/> <div className=" ">
           
          <ToastContainer />
        </div> </span>): null}</h4>
    {props.data.differential != null && props.data.differential.length > 0 ? (
      <ul className="list-paragraph">
        {props.data.differential.map((item, index) => (
          <li className="list-item" key={index}>
           <span > <a href="#" onClick={(event) => handleDifferentialLinkClick(event, item.preferredName)}  >{item.preferredName}</a></span>: {item.shortDescription} 
          </li>
        ))}
      </ul>
    ) : (
      <p>No data yet.</p>
    )}
  </div>
)}
    
    </div>
  
  )} 
       
        <h5>Diagnostic work-up</h5>
        {( isDiagnosisWorkupLoading) && <p className='loading-message'>
            <FontAwesomeIcon icon={faSpinner} spin /> Generating Diagnostic work-up...
          </p>}

        {props.data.diagnostic != null && props.data.diagnostic.diagnostic_workup ? (
          <ul className="list-paragraph">
  
            {props.data.diagnostic.diagnostic_workup.map((item, index) => (
              <li className="list-item" key={index}>  {item}  </li>
            ))}
          </ul>) : (
          <p>No data yet.</p>
        )}
  
  
        <h4>Staging</h4>
        { props.data.diagnostic != null && props.data.diagnostic ? ( <p>{props.data.diagnostic.staging}</p>
       ):(<p>No data yet.</p>)}
  
        <h4>Subtyping</h4>
        {props.data.diagnostic != null && props.data.diagnostic.subtyping ? (
          <ul className="list-paragraph">
  
            {props.data.diagnostic.subtyping.map((item, index) => (
              <li className="list-item" key={index}>  {item}</li>
            ))}
          </ul>) : (
          <p>No data yet.</p>
        )}
  
  
  
        <h4>Treatment</h4>
        <p >{props.data.treatment} </p>
   
       
       
        {(props.data.reference!=null && props.data.reference.length>0) && (<span> <br></br> <h4>Online references</h4> <ReferencePopupButton data={props.data.reference} label="open references"></ReferencePopupButton></span>)}  

        <p>Go to next: <a href="#" onClick={() => {
          handleTabChange('biomarkers');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}   >Biomarkers</a></p>
      </div>
  
    );
  }

  function ReferenceTable(props) {
    return (
      <div >

        <ReferenceList data={props.data} showTagsButton={true} ></ReferenceList>

       


      </div>
    );
  }

  function BiomarkerTable(props) {
    const [selectedRow, setSelectedRow] = React.useState(null);
    ReactGA.send({ hitType: "pageview", page: "/report/"+reportId+"/biomarkers", title: "B"+reportId });
    const openDialog = (row) => {
      setSelectedRow(row);
    };
  
    const closeDialog = () => {
      setSelectedRow(null);
    };

      return (
        <>
        <div className="d-none d-sm-block">
        <table responsive={true}>
          <thead>
            <tr>
              <th>Biomarker</th>
            {/*   <th>Result</th>*/}
              <th>Description</th>
              <th>Clinical significance</th>
             {/*  <th>ESCAT Actionability</th>*/}
              <th>Therapy</th>
             {/*  <th>Gene family</th>*/}
            {/*   <th>HGNC ID</th>*/}
              <th>Assay</th>
            {/*   <th>Prevalence</th>*/}
              <th>Note</th>
              <th>Controversies</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.data && Array.isArray(props.data)  ? props.data.map(row => (
              <tr key={row.id}>
                <td>{row.biomarker} </td>
            {/*    <td>{row.biomarker_result}</td> */}
                <td>{row.description}</td>
                <td>{row.clinical_significance}</td>
              {/*    <td>{row.actionability}</td>*/}
            {/*      <td>{row.therapy}</td>*/}
                <td>{row.treatment.join(", ")}</td>
               {/*   <td>{row.gene_family}</td>*/}
              {/*    <td>{row.HGNC_ID}</td>*/}
                <td>{row.assay}</td>
              {/*    <td>{row.prevalence}</td>*/}
                <td>{row.common_note}</td>
                <td>{row.controversies}</td>
                <td>{(row.reference!=null && row.reference.length>0) && <ReferencePopupButton data={row.reference}></ReferencePopupButton>}  </td>
              </tr>
            )) :  
              <p>No data available yet.</p>
            }
          </tbody>
        </table>
   

 

        </div>


        <div className="d-sm-none">
        {props.data && Array.isArray(props.data) ? (
          props.data.map((row) => (
            <div key={row.id} className="mb-3">
              
              <Button variant="primary" onClick={() => openDialog(row)}>
                   {row.biomarker}
              </Button>
            </div>
          ))
        ) : null}
      </div>
             
                
<Modal show={selectedRow !== null} onHide={closeDialog}>
<Modal.Header closeButton>
  <Modal.Title>Details</Modal.Title>
</Modal.Header>
<Modal.Body>
  {selectedRow && (
    <div>
      <strong>Biomarker: </strong> {selectedRow.biomarker}
      <br />
      <strong>Description: </strong> {selectedRow.description}
      <br />
      <strong>Clinical significance: </strong>{' '}
      {selectedRow.clinical_significance}
      <br />
      <strong>Therapy: </strong>{' '}
      {selectedRow.treatment.join(', ')}
      <br />
      <strong>Assay: </strong> {selectedRow.assay}
      <br />
      <strong>Note: </strong> {selectedRow.common_note}
      <br />
      <strong>Controversies: </strong>{' '}
      {selectedRow.controversies}
      <br />
  
       {(selectedRow.reference!=null && selectedRow.reference.length>0) && (<span> <b>Online references:</b> <ReferencePopupButton data={selectedRow.reference} label="open references"></ReferencePopupButton></span>)}  

    </div>
  )}
</Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={closeDialog}>
    Close
  </Button>
</Modal.Footer>
</Modal>

</>
  );
}
  
    
  
  function TreatmentTable(props) {
    const [selectedRow, setSelectedRow] = React.useState(null);
    ReactGA.send({ hitType: "pageview", page: "/report/"+reportId+"/treatment", title: "Rx"+reportId });
    const openDialog = (row) => {
      setSelectedRow(row);
    };
  
    const closeDialog = () => {
      setSelectedRow(null);
    };

    if (!isTreatmentLoading && (!props.data || props.data.length === 0)) {
      return <p>No data available yet</p>;
    }

    
    const groupedData = props.data.reduce((result, row) => {
      if (!row.brandname || !row.genericname) {
        // Create a parent row
        result.push({
          ...row,
          brandNames: [], // Initialize an array to store brand names
          children: [],   // Initialize an array to store child rows
        });
      } else {
        // Find the parent row for this child
        const parent = result.find((parentRow) => parentRow.name === row.genericname);
        if (parent) {
          parent.brandNames.push(row.name); // Add brand name to parent's brandNames array
          parent.children.push(row);        // Add child row to parent's children array
        } else {
          // If there's no matching parent, create a parent row for the child
          result.push({
            ...row,
            brandNames: [row.brandname], // Initialize the brandNames array with the child's name
            children: [],           // Initialize an array to store child rows
            name: `${row.genericname}`
          });
        }
      }
      return result;
    }, []);

      return (
        <>
        <div className="d-none d-sm-block">

        <table>
          <thead>
            <tr>
              <th>Line of therapy</th>
              <th>Treatment</th>
              <th>Category</th>
              <th>Drug class</th>
              <th>Description</th>
              <th>Rationale</th>
              <th>USA FDA Approval status</th>
              <th>EU EMA Approval status</th>
              <th>Common note</th>
             <th></th>
            </tr>
          </thead>
          <tbody>
     

{groupedData && groupedData.length > 0 ? groupedData.map((row, index) => (
          <React.Fragment key={row.id}>
            <tr>
              <td>{row.line_of_therapy}</td>
              <td>{row.name}  {' '}
                {row.brandNames.map((brandName, index) => (
                  <span key={brandName}>
                    {index > 0 ? ', ' : '('}
                    {brandName}
                    {index === row.brandNames.length - 1 ? ')' : ''}
                  </span>
                ))}</td>
              <td>{row.category}</td>
              <td>{row.drug_class}</td>
              <td>{row.description}</td>
              <td>{row.rationale}</td>
              <td> {row.fda_approval_status === "withdrawn" ? (
    <FontAwesomeIcon icon={faExclamationCircle} color="red"  style={{ marginRight: '8px' }}  />
  ) : null}
   {row.fda_approval_status}</td>
              <td  >{row.ema_approval_status === "withdrawn" ? (
    <FontAwesomeIcon icon={faExclamationCircle} color="red"  style={{ marginRight: '8px' }}  />
  ) : null}
   {row.ema_approval_status}</td>
              <td>{row.common_note}</td>
              <td>
                {row.reference !== null && row.reference.length > 0 && (
                  <ReferencePopupButton data={row.reference} />
                )}
              </td>
            </tr>
        
          </React.Fragment>
        ))  : (
          <p>No data available yet.</p>
        )}

          </tbody>
        </table></div>


 



        <div className="d-sm-none">
        {props.data && Array.isArray(props.data) ? (
          props.data.map((row) => (
            <div key={row.id} className="mb-3">
              
              <Button variant="primary" onClick={() => openDialog(row)}>
                   {row.name}
              </Button>
            </div>
          ))
        ) : null}
      </div>

      
<Modal show={selectedRow !== null} onHide={closeDialog}>
<Modal.Header closeButton>
  <Modal.Title>Details</Modal.Title>
</Modal.Header>
<Modal.Body>
  {selectedRow && (
    <div>
         <div>
         {selectedRow.line_of_therapy ? (
  <p className="mb-3">
    <strong>Line of therapy:</strong> {selectedRow.line_of_therapy}
  </p>
) : null}
              <p className="mb-3">
                <strong>Treatment:</strong> {selectedRow.name}
              </p>
              <p className="mb-3">
                <strong>Category:</strong> {selectedRow.category}
              </p>
              <p className="mb-3">
                <strong>Drug class:</strong> {selectedRow.drug_class}
              </p>
              <p className="mb-3">
                <strong>Description:</strong> {selectedRow.description}
              </p>
              <p className="mb-3">
                <strong>Rationale:</strong> {selectedRow.rationale}
              </p>
              <p className="mb-3">
                <strong>FDA Approval status:</strong> {selectedRow.fda_approval_status}
              </p>
              <p className="mb-3">
                <strong>EMA Approval status:</strong> {selectedRow.ema_approval_status}
              </p>
              <p className="mb-3">
                <strong>Common note:</strong> {selectedRow.common_note}
              </p>
            </div>
    <br />
  
    {(selectedRow.reference!=null && selectedRow.reference.length>0) && (<span> <b>Online references:</b> <ReferencePopupButton data={selectedRow.reference} label="open references"></ReferencePopupButton></span>)}  

 </div>
)}
</Modal.Body>
<Modal.Footer>
<Button variant="secondary" onClick={closeDialog}>
 Close
</Button>
</Modal.Footer>
</Modal>


        </>
      );
    }
  
  
  
    
  function LifestyleTable(props) {
    const [selectedRow, setSelectedRow] = React.useState(null);
    ReactGA.send({ hitType: "pageview", page: "/report/"+reportId+"/lifestyle", title: "L"+reportId });
    const openDialog = (row) => {
      setSelectedRow(row);
    };
  
    const closeDialog = () => {
      setSelectedRow(null);
    };

    if( isLifestyleInfoVisible &&  !isLifestyleLoading && (reportData.lifestyle===null || reportData.lifestyle.length===0))  {
      console.log("in lifestyle tbl - should fetch lifestyle for ",reportId);  
      //fetchLifestyle(reportId);
    }


      return (
        <>
        <div className="d-none d-sm-block">
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Lifestyle</th>
              <th>Rationale</th>
              <th>Evidence level</th>
              <th>Accessibility</th>
              <th></th>
             
            </tr>
          </thead>
          <tbody>
            {props.data && Array.isArray(props.data)  ? props.data.map(row => (
              <tr key={row.id}>
                <td>{row.type}</td>
                <td>{row.lifestyle}</td>
                <td>{row.rationale}</td>
                <td>{row.evidence_level}</td>
                <td>{row.accessibility}</td>
                <td>{(row.reference!=null && row.reference.length>0) && <ReferencePopupButton data={row.reference}></ReferencePopupButton>}  </td>
              </tr>
            )) : null}
          </tbody>
        </table></div>

        
        <div className="d-sm-none">
        {props.data && Array.isArray(props.data) ? (
          props.data.map((row) => (
            <div key={row.id} className="mb-3">
              
              <Button variant="primary" onClick={() => openDialog(row)}>
                   {row.lifestyle}
              </Button>
            </div>
          ))
        ) : null}
      </div>

      
<Modal show={selectedRow !== null} onHide={closeDialog}>
<Modal.Header closeButton>
  <Modal.Title>Details</Modal.Title>
</Modal.Header>
<Modal.Body>
  {selectedRow && (
    <div>
  <p className="mb-3">
    <strong>Type:</strong> {selectedRow.type}
  </p>
  <p className="mb-3">
    <strong>Lifestyle:</strong> {selectedRow.lifestyle}
  </p>
  <p className="mb-3">
    <strong>Rationale:</strong> {selectedRow.rationale}
  </p>
  <p className="mb-3">
    <strong>Evidence level:</strong> {selectedRow.evidence_level}
  </p>
  <p className="mb-3">
    <strong>Accessibility:</strong> {selectedRow.accessibility}
  </p>
  {(selectedRow.reference!=null && selectedRow.reference.length>0) && (<p> <b>Online references:</b> <ReferencePopupButton data={selectedRow.reference} label="open references"></ReferencePopupButton></p>)}  

</div>

)}
</Modal.Body>
<Modal.Footer>
<Button variant="secondary" onClick={closeDialog}>
 Close
</Button>
</Modal.Footer>
</Modal>
        </>
      );
    }



    function ExpertTable(props) {
     
        return (
          <table>
            <thead>
              <tr>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>credentials</th>
                <th>organisation</th>
                <th>country</th>
                <th>note</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.data && Array.isArray(props.data)  ? props.data.map(row => (
                <tr key={row.id}>
                  <td>{row.firstname}</td>
                  <td>{row.lastname}</td>
                  <td>{row.credentials}</td>
                  <td>{row.organisation_name}</td>
                  <td>{row.country}</td>
                  <td>{row.common_note}</td>
                  <td>{(row.reference!=null && row.reference.length>0) && <ReferencePopupButton data={row.reference}></ReferencePopupButton>}  </td>
                </tr>
              )) : null}
            </tbody>
          </table>
        );
      }
    
  
  function Table(props) {
  console.log(props);
    return (
      <table>
        <thead>
          <tr>
            <th>Column 1</th>
            <th>Column 2</th>
          </tr>
        </thead>
        <tbody>
          {props.data ? props.data.map(row => (
            <tr key={row.id}>
              <td>{row.column1}</td>
              <td>{row.column2}</td>
            </tr>
          )) : null}
        </tbody>
      </table>
    );
  }
 
  function getSelectedSection(){
    if (isDiseaseInfoVisible)
      return "/DiseaseInfo";
      if (isPersoPageVisible)
      return "/PersoPage";
    if (isBiomarkersInfoVisible)
      return "/Biomarkers";
    if (isRxInfoVisible)
      return "/Treatments";
    if (isLifestyleInfoVisible)
      return "/Lifestyles";
    if (isExpertInfoVisible)
      return "/Experts";
    
      
      return "";
  }

  return (
    <div className="report-container">


{(!reportData || reportData.id == '') &&  <div className="error-message">Not report found.</div>}

{(reportData && reportData.id != '') && 
<span>
<div className="share-button-container">
 <ShareButton></ShareButton>
	
         

 <div className=" " style={{marginRight:'8px'}}>
       
          <button className="regenerate-button mr-2 " onClick={openModal}  title="AI observations and thoughts on the references"><FontAwesomeIcon className="share-icon" icon={faHatWizard}  title="AI observations and thoughts on the references" /></button>
      <AIObservationModal  isOpen={modalIsOpen} onRequestClose={closeModal}  onGenerate={handleAIObersationsClick}/>
        </div>
        <div className="mr-2 ">
        <button className="regenerate-button" onClick={openUpdatesModal}  title="check for live updates"><FontAwesomeIcon className="share-icon" icon={faSync}  title="live updates" /></button>
        <LiveUpdatesModal  isOpen={updatesModalIsOpen} onRequestClose={closeUpdatesModal}  onGenerate={handleCheckUpdate}/>
      </div>
         <NewReportButtonNoText></NewReportButtonNoText>

</div>


<div  className='casedesc' >   <span style={{ textAlign: 'left', fontWeight:'600', paddingLeft:'5px' }} >Case Description: </span>
        <CaseDescription data={reportData.caseDescription}></CaseDescription >

        </div>


        <h2 className="report-page-title">Report {( isDiagnosisWorkupLoading || isBiomarkerLoading || isTreatmentLoading || isLifestyleLoading || isLoading) &&  
           <span> <FontAwesomeIcon icon={faSpinner} spin title="Scanning references and Generating Scientific Overview (it may take a minute)..."/> </span>}
 

          </h2>
        <NavigationMenu
          setPersoPageVisible={setPersoPageVisible}
  setDiseaseInfoVisible={setDiseaseInfoVisible}
  setBiomarkersInfoVisible={setBiomarkersInfoVisible}
  setRxInfoVisible={setRxInfoVisible}
  setLifestyleInfoVisible={setLifestyleInfoVisible}
  setExpertsInfoVisible={setExpertsInfoVisible}
  setReferencesInfoVisible={setReferencesInfoVisible}
  isBiomarkerLoading={isBiomarkerLoading}
  isTreatmentLoading={isTreatmentLoading}
  isLifestyleLoading={isLifestyleLoading} 
  handleTabChange={handleTabChange}  
/>
 
 <div className="report-navigation  d-none d-sm-block" >  <ul className="menu-items">
  
 {persoPage != null && persoPage.actionable_insights != null  && (  <React.Fragment> 
            <li><a href="#" onClick={() => {

              handleTabChange('persoPage');
            }}
              className={isPersoPageVisible ? 'active' : ''}

              style={{
                color: persoPage !== null     && isPersoPageVisible ? '#12a587' :    persoPage === null  ? 'gray' : 'inherit', 
                cursor: persoPage === null      ? 'default' : 'pointer',
              }}

              Perso Insights
           >
                Your insights   <FontAwesomeIcon icon={faLightbulb } style={{ color: 'darkorange' , textShadow: '0 0 8px rgba(255, 255, 255, 0.8)', }} />
            </a></li>

            •
            </React.Fragment>
            )}
  
  <li><a href="#"  onClick={() => {
      setPersoPageVisible(false);
          setDiseaseInfoVisible(true);
          setBiomarkersInfoVisible(false);
          setRxInfoVisible(false);
          setLifestyleInfoVisible(false);
          setExpertsInfoVisible(false);
          setReferencesInfoVisible(false);
          handleTabChange('diseaseInfo');
        }} 
        className={isDiseaseInfoVisible? 'active' : ''  }   
        
        style={{
          color: reportData.generalInfo !== null  && isDiseaseInfoVisible ? '#12a587' :    reportData.generalInfo === null  ? 'gray' : 'inherit', 
        }}>
        
        Disease Info  {( isDiagnosisWorkupLoading) &&  
            <FontAwesomeIcon icon={faSpinner} spin />  
          }
      </a></li> • <a href="#"  onClick={() => {
        
          handleTabChange('biomarkers');
        }}
        className={isBiomarkersInfoVisible? 'active' : ''  }
        
        style={{
          color: reportData.standardBiomarker !== null && reportData.standardBiomarker.length > 0 && isBiomarkersInfoVisible ? ' #12a587' :    reportData.standardBiomarker === null ||  reportData.standardBiomarker.length === 0 ? 'gray' : 'inherit', 
      
          cursor: reportData.standardBiomarker === null ||  reportData.standardBiomarker.length === 0 ? 'default' : 'pointer',
        }}
        disabled={reportData.standardBiomarker === null || reportData.standardBiomarker.length === 0}>
        
     Biomarkers   {( isBiomarkerLoading) &&  
            <FontAwesomeIcon icon={faSpinner} spin />  
          }
      </a>  • <a href="#"   onClick={() => {
           handleTabChange('treatments');
        }}
        
        className={isRxInfoVisible? 'active' : ''  }

        style={{
          color: reportData.standardRx !== null && reportData.standardRx.length > 0 && isRxInfoVisible ? '#12a587' :    reportData.standardRx === null ||  reportData.standardRx.length === 0 ? 'gray' : 'inherit', 
          cursor: reportData.standardRx === null ||  reportData.standardRx.length === 0 ? 'default' : 'pointer',
        }}
        disabled={reportData.standardRx === null || reportData.standardRx.length === 0} >
       Treatments {( isTreatmentLoading) &&  
            <FontAwesomeIcon icon={faSpinner} spin />  
          }
      </a> •<a href="#"  onClick={() => {
         handleTabChange('lifestyles');
        }}          className={isLifestyleInfoVisible? 'active' : ''  }

        style={{
          color: reportData.lifestyle !== null && reportData.lifestyle.length > 0 && isLifestyleInfoVisible ? '#12a587' :    reportData.lifestyle === null ||  reportData.lifestyle.length === 0 ? 'gray' : 'inherit', 
    
          cursor: reportData.lifestyle === null ||  reportData.lifestyle.length === 0 ? 'default' : 'pointer',
        }}
        disabled={reportData.lifestyle === null || reportData.lifestyle.length === 0} >
      Lifestyles {( isLifestyleLoading) &&  
            <FontAwesomeIcon icon={faSpinner} spin />  
          }
      </a> { TOGGLE_ON_EXPERTS && <span> • <a href="#"   onClick={() => {
         handleTabChange('experts');
        }}      className={isExpertInfoVisible? 'active' : ''  }

        style={{
          color: reportData.expert !== null && reportData.expert.length > 0 && isExpertInfoVisible ? '#12a587' :    reportData.expert === null ||  reportData.expert.length === 0 ? 'gray' : 'inherit', 
    
          cursor: reportData.expert === null ||  reportData.expert.length === 0 ? 'default' : 'pointer',
        }}
        disabled={reportData.expert === null || reportData.expert.length === 0}
      > 
       Experts {( isExpertLoading) &&  
            <FontAwesomeIcon icon={faSpinner} spin />  
          }
      </a> </span>}• <a href="#"   onClick={() => {
          handleTabChange('references');
        }}      className={ isReferencesInfoVisible? 'active' : ''  }

        style={{
          color: isReferencesInfoVisible ? '#12a587' :    'inherit' 
        }}
 
      > 
       References {( isReferenceLoading) &&  
            <FontAwesomeIcon icon={faSpinner} spin />  
          }
      </a> •<a href="#"  onClick={() => {
          handleTabChange('sendFeedback');
        }}
        style={{
          color: (!isPersoPageVisible && !isDiseaseInfoVisible  && !isBiomarkersInfoVisible && !isRxInfoVisible&& !isLifestyleInfoVisible  && !isExpertInfoVisible && !isReferencesInfoVisible) ? '#12a587'    : 'inherit', 
    
      
        }}
        >
       Send Feedback
      </a> </ul>  </div>
 

      {isPersoPageVisible && <PersoPage data={persoPage} />}

          {/* Conditionally render DiseaseInfo based on isDiseaseInfoVisible */}
          {isDiseaseInfoVisible && <DiseaseInfo data={reportData.generalInfo} />}
       
          {isBiomarkersInfoVisible &&
            <div className="section-container" >
              {isBiomarkersInfoVisible && <h3>Standard Biomarkers</h3>}
              {(isBiomarkersInfoVisible && !isBiomarkerLoading && (reportData.standardBiomarker===null || reportData.standardBiomarker.length===0)) && <p className='loading-message'>
            Queued Biomarkers table 
          </p>}

              {(isBiomarkersInfoVisible && isBiomarkerLoading) && <p className='loading-message'>
                <FontAwesomeIcon icon={faSpinner} spin /> Generating biomarkers (please wait or come back soon)...

              </p>}

              {isBiomarkersInfoVisible && <BiomarkerTable data={reportData.standardBiomarker} />}

              {isBiomarkersInfoVisible && <h3>Emerging Biomarkers</h3>}

              {isBiomarkersInfoVisible && <BiomarkerTable data={reportData.emergingBiomarker} />}

              {isBiomarkersInfoVisible &&   <p>Go to next: <a href="#" onClick={() => {
          handleTabChange('treatments');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}   >Treatments</a></p>}

            </div>}

   


          {isRxInfoVisible && <h3 className="report-section-title">Standard Treatments</h3>}
          {(isRxInfoVisible && isTreatmentLoading) && <p className='loading-message'>
            <FontAwesomeIcon icon={faSpinner} spin /> Generating treatments table (please wait or come back soon)...

          </p>}
          {(isRxInfoVisible && !isTreatmentLoading && (reportData.standardRx===null || reportData.standardRx.length===0)) && <p className='loading-message'>
            Queued treatments table 
          </p>}
          {isRxInfoVisible && <TreatmentTable data={reportData.standardRx} />}
          {isRxInfoVisible && <h3>Emerging Treatments</h3>}
          {isRxInfoVisible && <TreatmentTable data={reportData.emergingRx} />}


          {isRxInfoVisible &&  <p>Go to next: <a href="#" onClick={() => {
          handleTabChange('lifestyles');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}   >Lifestyles</a></p>}

  {isLifestyleInfoVisible && <h3 className="report-section-title">Evidence-based Lifestyles</h3>  }
  {(isLifestyleInfoVisible &&  isLifestyleLoading) && <p className='loading-message'>
            <FontAwesomeIcon icon={faSpinner} spin /> Generating lifestyle table (please wait or come back soon)...
          </p>}
          {(isLifestyleInfoVisible &&  !isLifestyleLoading && (reportData.lifestyle===null || reportData.lifestyle.length===0)) && <p className='loading-message'>
            Queued lifestyle table 
          </p>}
  {isLifestyleInfoVisible && <LifestyleTable data={reportData.lifestyle} />}


  {isExpertInfoVisible && <h3 className="report-section-title">World-leading experts</h3>  }
  {(isExpertInfoVisible &&  isExpertLoading) && <p className='loading-message'>
            <FontAwesomeIcon icon={faSpinner} spin /> Generating experts table (please wait or come back soon)...
          </p>}
          {(isExpertInfoVisible &&  !isExpertLoading && (reportData.expert === null || reportData.expert.length===0)) && <p className='loading-message'>
            Queued experts table 
          </p>}
  {isExpertInfoVisible && <ExpertTable data={reportData.expert} />}
 


          {isReferencesInfoVisible && <h3 className="report-section-title">References</h3>  }
          {( isReferencesInfoVisible&&  !isReferenceLoading && (!reportData.reference || reportData.reference?.length===0)) && <p className='loading-message'>
            Queued reference table 
          </p>}
  { isReferencesInfoVisible&& <ReferenceTable data={reportData.allreference} />}

  <hr className="before-feedback"></hr>
  { (isReferencesInfoVisible  ||  isDiseaseInfoVisible  || isBiomarkersInfoVisible || isRxInfoVisible || isLifestyleInfoVisible) &&  <NavigationMenu 
 setDiseaseInfoVisible={setDiseaseInfoVisible}
 setBiomarkersInfoVisible={setBiomarkersInfoVisible}
 setRxInfoVisible={setRxInfoVisible}
 setLifestyleInfoVisible={setLifestyleInfoVisible}
 setExpertsInfoVisible={setExpertsInfoVisible}
 setReferencesInfoVisible={setReferencesInfoVisible}
 isBiomarkerLoading={isBiomarkerLoading}
 isTreatmentLoading={isTreatmentLoading}
 isLifestyleLoading={isLifestyleLoading}
 handleTabChange={handleTabChange}  
/>}

  {(reportData && reportData.id != '' && !isPersoPageVisible)     && 
 <FeedbackForm onSubmit={handleFeedbackSubmit} />} 
 

 {reportData.generatedWithModel && reportData.generatedWithModel.name!=null ?  (
        <div className="info-panel"> <span>AI Generated • {formattedDate ? (
          <span>{formattedDate}</span>
        ) : (
          <span>{reportData.generatedWithModel.version}</span>
        )} version • For research purpose only. You can help improving the accuracy by submitting feedback.</span></div>  
          
        ) :'' }  </span>}
    </div>
  );
};

export default Report;

//Limitation: can invent facts sometimes.  {reportData.generatedWithModel.description} <br></br>