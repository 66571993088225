import React, { useState } from 'react';
import config from './../config';


function ContactForm  ( ) {
  const [isFeedbackSaved, setIsFeedbackSaved] = useState(false);

  const [note, setNote] = useState(''); 
  const [email, setEmail] = useState('');


  const handleFeedbackSubmit = (formData) => {
    const server_url = config.server_url;
   const feedback = {};
    feedback.accuracy = 'NOT_SET';
    feedback.helpfulness = 'NOT_SET';
    feedback.note = formData.email + " " + formData.note;
    feedback.scientificOverviewId = -1;
    feedback.pageWhenReporting = window.location.pathname ;
    feedback.reportWhenReporting = '';
  
    fetch(`${server_url}/api/feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(feedback)
    })
    .then(response => response.json())
    .then(data => {
      console.log("contact message saved", data);    });
      setIsFeedbackSaved(true);
  };
  
  

  const handleSubmit = (event) => {
    event.preventDefault();
    // Send feedback to server or do other handling here
  
    const formData = {
 email,
      note,
    };
    handleFeedbackSubmit(formData);
    setIsFeedbackSaved(true);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  
  return ( 
    <form  className="contact-container" onSubmit={handleSubmit}>

            {isFeedbackSaved && <p  className="success-message">Thank you for your feedback!</p>}
      
      <div>
        <label htmlFor="note">Message: </label>
        <input className="contact-email"
          type="email"
          value={email}
          placeholder="Your email (optional)"
          onChange={handleEmailChange}
        />
        <textarea className="contact-textarea" id="note" value={note}   placeholder="Share your thoughts, or contact us for partnership opportunities ..." onChange={(event) => setNote(event.target.value)} />

      </div>
      <button type="submit">Submit</button>
    </form>
  );
}

export default ContactForm;
