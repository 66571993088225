import React, { useEffect, useRef, useState } from 'react';
 
import config from '../config';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHatWizard, faSpinner } from '@fortawesome/free-solid-svg-icons';


function ReferenceList({ data, showTagsButton }) {


  const componentRef = useRef();
  
    const [selectedTags, setSelectedTags] = useState([
        'General Info',
    
      ]);
      const server_url = config.server_url;


   
      if(data ==null){
       return;
      }


      const handleImageError = (e) => {
        e.target.src = process.env.PUBLIC_URL + "/images/fallback-favicon.png";
      };

    const handleTagClick = (tag, event) => {
        event.preventDefault();
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
        } else {
            setSelectedTags([tag]);
        }
    };

    const handleCheckReferenceClick = ( data, event, useData) => {
      event.preventDefault();
    console.log(data);

    data.loading=true;

    fetch(`${server_url}/api/reference/checkDrugApproval`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(da => {
      console.log("handleCheckReferenceClick response", da);
       data.observations = da; 
       data.loading=false;
       // Create a copy of useData array to maintain immutability
    const updatedData = useData.map(row => {
      if (row.id === data.id) {
        return {
          ...row,
          observations: da
        };
      }
      return row;
    });

    /* FIXME
    setUseData((prevUseData) =>
    prevUseData.map((r) =>
      r.id === data.id ? { ...r, loading: true } : r
    )
  );*/
        // Update the state with the modified array
        useData = updatedData;
        // Force a re-render of the component
        componentRef.current.forceUpdate();
      }).catch(error => {
        data.loading=false;

        // Handle errors here
        console.error('Fetch error:', error);
      });
    


  };


 
    const filteredData = data.filter((row) => {
        const satisfiesCondition = selectedTags.every((tag) => {
            const condition = row.insightType === tag || row.insight === tag;

            return condition;
        });

        

        return satisfiesCondition;
    });

 
    let useData=data;
    if(showTagsButton){
        useData=filteredData;
    }
 

    //console.log("useData ", useData);
  return (
    <div>

   
       {showTagsButton &&  (<div className="filter-options">

  
       <button
    className={`button ${selectedTags.includes('General Info') ? 'statuson' : ''}`}
  style={{ marginRight: '10px' }}
  onClick={(event) => handleTagClick('General Info', event)}
>
  Disease Info
</button>
        <button
         className={`button ${selectedTags.includes('Biomarkers') ? 'statuson' : ''}`}
          style={{ marginRight: '10px' }}
          onClick={(event) => handleTagClick('Biomarkers', event)}
        >
          Biomarker
        </button>
        <button
          className={`button ${selectedTags.includes('Treatments') ? 'statuson' : ''}`}
          style={{ marginRight: '10px' }}
          onClick={(event) =>handleTagClick('Treatments', event)}
        >
          Treatment
        </button>
        <button
          className={`button ${selectedTags.includes('Lifestyle') ? 'statuson' : ''}`}
          style={{ marginRight: '10px' }}
          onClick={(event) => handleTagClick('Lifestyle', event)}
        >
          Lifestyle
        </button>
        <button
            className={`button ${selectedTags.includes('Experts') ? 'statuson' : ''}`}
          style={{ marginRight: '10px' }}
          onClick={(event) => handleTagClick('Experts', event)}
        >
          Expert
        </button>
       

      </div>)}

          { useData.map((row) => (

 
               <div className="reference" key={row.id}  >
 
             {showTagsButton && (    <span>  
                

                   <span className="tag">{row.insightType}</span>
                {row.insight &&  <span className="tag">{row.insight}</span>}</span>)}

<img style={{maxWidth:'28px'}} src={process.env.PUBLIC_URL + "/images/"+row.favicon}  title={row.displayLink} onError={handleImageError} alt="Ref Favicon"></img>
          
              

                <a  title={"Click to read on "+row.displayLink}href={row.link} target="_blank" rel="noopener noreferrer"  >  
             <span>   {row.journal ? row.journal : row.displayLink}</span>
                  <h3 style={{paddingTop:'3px'}} dangerouslySetInnerHTML={{ __html: row.htmlTitle }}></h3>
                  <p className="reference-text" > {row.date}  </p>
                  <p className="reference-text" dangerouslySetInnerHTML={{ __html: row.htmlSnippet }}></p>

                
                 
                  </a>
                
                  {row.observations && (  
                    <div style={{backgroundColor:"#f080803b" , padding:"8px"}}>
                      <div style={{  display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <h6 style={{ margin: 0 }}> <FontAwesomeIcon className="share-icon" icon={faHatWizard}  title="AI observations and thoughts on the reference" />   AI Observations</h6>
  
    </div>
                                           <p>Intervention(s): 
                       <ul>
        {row.observations.approvedDrugNamesFoundInTitleOrText.map(drug => (
          <li
            key={drug}
            style={{ fontWeight: row.reportName === drug ? 'bold' : 'normal' }}
          >
            {drug}
          </li>
        ))}
      </ul></p>

                       <p>Indication(s): 
      <ul>
        {row.observations.indicationsFoundInTitleOrText.map(indication => (
          <li
            key={indication}
            style={{ fontWeight: row.reportName.toLowerCase().indexOf(indication.toLowerCase()) >=0? 'bold' : 'normal' }}
          >
            {indication}
          </li>
        ))}
      </ul>

      </p>
                  {row.observations.comparator && (
                  
                  
                  <p  >Comparator: <ul>
                  
                    <li
                       
                    >
                     {row.observations?.comparator}
                    </li>
                  
                </ul>
                  </p>)}


                  {row.observations.outcome && (<p  >Outcomes:<ul>
                  
                  <li
                     
                  >
                   {row.observations?.outcome}
                  </li>
                
              </ul>  
                  </p>)}

   
                    <p style={{color:"green", fontWeight:"600", lineHeight:"1.6"}}>Findings: {row.observations?.findings} 
                    </p>
                        
                    {row.observations.thoughts && (   <p style={{color:"purple", fontWeight:"600", lineHeight:"1.6"}}>Thoughts: {row.observations?.thoughts} 
                    </p>)}
                    </div>)}
              </div>
          ))}
    </div>
  );
}

export default ReferenceList;