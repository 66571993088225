import React, { useState } from 'react';

function FeedbackForm  ({ onSubmit }) {
  const [accuracy, setAccuracy] = useState('NOT_SET');
  const [helpfulness, setHelpfulness] = useState('NOT_SET');
  const [note, setNote] = useState('');
  const [email, setEmail] = useState('');
  const [isFeedbackSaved, setIsFeedbackSaved] = useState(false);

  const handleInputChange = () => {
    // Set isFeedbackSaved to false when any input value changes
    setIsFeedbackSaved(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Send feedback to server or do other handling here
    //console.log(`Accuracy: ${accuracy}, Helpfulness: ${helpfulness}, Note: ${note}`);
    const formData = {
      accuracy,
      helpfulness,
      note,
      email
    };
    onSubmit(formData);
    setIsFeedbackSaved(true);
  };

  
  return (
    <form className="feedback-form" onSubmit={handleSubmit}>
      <h2>Feedback Form</h2>
    <p className="text-center  text-muted">If you suggest missing treatments, it typically replies within an hour</p> 
            {isFeedbackSaved && <p  className="success-message">Feedback sent! Thank you.</p>}
      <div  >
        <label htmlFor="accuracy">Was the report accurate?</label>
        <select id="accuracy" value={accuracy} onChange={(event) => { setAccuracy(event.target.value); handleInputChange(); }}>
          <option value="NOT_SET">Please select</option>
          <option value="ACCURATE">Accurate</option>
          <option value="NOT_ACCURATE">Not Accurate</option>
        </select>
      </div>
      <div>
        <label htmlFor="helpfulness">Was the report helpful?</label>
        <select id="helpfulness" value={helpfulness} onChange={(event) => {setHelpfulness(event.target.value); handleInputChange(); }}>
          <option value="NOT_SET">Please select</option>
          <option value="HELPFUL">Helpful</option>
          <option value="NOT_HELPFUL">Not Helpful</option>
        </select>
      </div>
  
      <div>
        <label htmlFor="note">Note:</label>
        <textarea id="note" rows={4}  className="full-width" value={note} onChange={(event) => {setNote(event.target.value); handleInputChange(); }}    placeholder="Enter your comments and suggestions regarding the report's accuracy and helpfulness (optional)" />
      </div>
      <div>  <input className="contact-email"
          type="email"
          value={email}
          placeholder="Your email (optional)"
          onChange={(event) => { setEmail(event.target.value); handleInputChange(); }}  
        /></div>
      <button type="submit">Submit</button> {isFeedbackSaved && <p  className="success-message">Feedback sent! Thank you.</p>}
    </form>
  );
}

export default FeedbackForm;
