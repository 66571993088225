import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, Popover, OverlayTrigger, Button } from 'react-bootstrap';
import config from './../config';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const PersonaliseDiseaseInfoForm = ({ onSubmit, onStep1Submit }) => {
  const [step, setStep] = useState(1);
  const [mainCondition, setMainCondition] = useState('');
  const [stage, setStage] = useState('');
  const [ageRange, setAgeRange] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [isDisease, setIsDisease] = useState(null);
  const [hasStages, setHasStages] = useState('');
  const [isValidatingDisease, setIsValidatingDisease] = useState(false);
  const [preferredDiseaseName, setPreferredDiseaseName] = useState('');
  const [instructions, setInstructions] = useState('');
  const server_url = config.server_url;
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [overview, setOverview] = useState('');
  const popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom" style={{ padding: '8px' }}>
      <strong>Personalised Medicine</strong> is using genetic information to tailor diagnosis, treatment, or prevention.
    </Popover>
  );
  const handleInstructionsChange = (e) => {
    setInstructions(e.target.value);
  };
  const validateForm = (e) => {
    setIsDisease(null);
    setHasStages(null);
    setPreferredDiseaseName(null);

    if(mainCondition==""){
     
      return;
    }
    setIsValidatingDisease(true);
 
    
    const formData = {
      mainCondition,
      stage,
      ageRange 
    };

    const formDataPerso = {
      formData,
      instructions
    };

  
      fetch(`${server_url}/api/validation/case_description?sendTo=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          setIsDisease(data.isDisease);
          setHasStages(data.hasStages);
          setPreferredDiseaseName(data.preferedName);
          setIsValidatingDisease(false);
        });
   



  };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value.trim();
    setEmail(enteredEmail);

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValid = enteredEmail === '' || emailPattern.test(enteredEmail);

    setIsEmailValid(isValid);

    if (!isValid) {
      setEmailErrorMessage('Please enter a valid email address');
    } else {
      setEmailErrorMessage('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (step === 1) {

      
      // Validate and move to the next step
      validateForm();

      
      if (isDisease) {

        setStep(2);


        const formData = {
          mainCondition,
          stage,
          ageRange 
        };
        onStep1Submit(formData);


       
      }
    } else if (step === 2) {
      // Submit the form
      const formData = {
        mainCondition,
        stage,
        ageRange,
        email,
        instructions,
        overview 
      };

      onSubmit(formData);
      navigate(`/report`);
 
     
    }
  };

  return (
    <div>
      <h1>Generate your Scientific Overview</h1>
  
      <p style={{ color: 'gray' }}>
        A scientific report on the latest advances in{' '}
        <OverlayTrigger trigger="focus" placement="bottom" overlay={popoverBottom}>
          <Button className="dotted-underline " variant="link">
            Personalised Medicine
          </Button>
        </OverlayTrigger>
      </p>

      <form className="landing-input-form" onSubmit={handleSubmit}>
        {step === 1 && (
          <>
            <div>
              <label htmlFor="diseaseName">Disease Name:</label>
              <input
                type="text"
                id="diseaseName"
                value={mainCondition}
                autoFocus={true}
                onChange={(e) => setMainCondition(e.target.value)}
                onBlur={validateForm}
                required
                placeholder="Enter any disease..."
              />
              {mainCondition != null && mainCondition.length > 3 && (isDisease != null && !isDisease) && (
                <span style={{ color: 'orange' }}>Enter a correct disease name. E.g. Breast Cancer or Psoriasis</span>
              )}
              {isValidatingDisease && <FontAwesomeIcon icon={faSpinner} spin />}
            </div>

            <div>
              <label htmlFor="diseaseStage">Disease Stage (if applicable):</label>
              <select
                value={(hasStages != null && !hasStages) ? 'NA' : stage}
                onChange={(event) => setStage(event.target.value)}
                disabled={hasStages != null && !hasStages}
              >
                <option value="">Select Stage</option>
                {hasStages != null && !hasStages && <option value="NA">Not applicable</option>}
                <option value="Stage 0">Stage 0</option>
                <option value="Stage 1">Stage 1</option>
                <option value="Stage 2">Stage 2</option>
                <option value="Stage 3">Stage 3</option>
                <option value="Stage 4">Stage 4</option>
              </select>
              {preferredDiseaseName != null &&
                preferredDiseaseName.length > 0 &&
                isDisease &&
                hasStages != null &&
                !hasStages && (
                  <span style={{ color: 'green' }}>{preferredDiseaseName} does not have stages</span>
                )}
            </div>

            <div>
              <label>
                Age:
                <select value={ageRange} onChange={(e) => setAgeRange(e.target.value)}>
                  <option value="">Select Age Range</option>
                  <option value="Elderly">Elderly</option>
                  <option value="Adult">Adult</option>
                  <option value="Adolescent">Adolescent</option>
                  <option value="Child">Child</option>
                  <option value="Infant">Infant</option>
                </select>
              </label>
            </div>
          </>
        )}

        {step === 2 && (   <><div>
 

    <div  >
    {step === 2 && (  <h5 style={{ color: 'green' }}>Step 1: Report to Generate: {ageRange} {mainCondition} {stage}</h5>)}
      <h5 className='pt-3'>Step 2: Personalise your report</h5>
      <p style={{ color: 'gray' }}>
        Enter your email to receive a link to your personalised report and scientific updates.
      </p>

      <div className="form-group">
        <label htmlFor="yourEmail">Your Email:</label>
        <input
          type="text"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter your email (optional)"
          className="form-control"
        />
        {emailErrorMessage && <div className="text-warning">{emailErrorMessage}</div>}
      </div>

      <div className="form-group">
        <label htmlFor="YourInstructions">Additional Details:</label>
        <textarea
          value={instructions}
          onChange={handleInstructionsChange}
          placeholder="Provide additional details to tailor your report (optional)"
          className="form-control"
          rows={4}
        />
      </div>

      
    </div>
  </div>
  </>
        )}

<div>

{step === 2 && (
    <button type="button" className="btn button" onClick={() => setStep(1)}>
      Back
    </button>
  )}

  <button type="submit" disabled={step === 1 ? !isDisease : false}>
    {step === 1 ? (
      <>
        <FontAwesomeIcon icon={faWandMagicSparkles} /> Next
      </>
    ) : (
      (email || instructions) ?       <>
      <FontAwesomeIcon icon={faWandMagicSparkles} /> Generate
    </> : 'Skip this step'
    )}
  </button>


</div>
      </form>

      <div>Free Research Preview. May produce inaccurate facts. <a href="/about">About MedGPT360</a></div>
    </div>
  );
};

export default PersonaliseDiseaseInfoForm;
