import React from 'react';
import { useState, useEffect } from 'react';

import { Nav, NavLink } from 'react-bootstrap';
import ContactForm from './ContactForm';
 
 
 
 

function Privacy() {
 
    document.title = 'Privacy Policy';

    return (
        <div  >
   
       <div className="container-fluid px-4 py-5 my-4 panel-left ">
 
<h1>Privacy Policy</h1>
<p className="mb-6">Last updated on July 19, 2023</p>
<p className="mb-6 col-md-8 col-lg-8 ">Thank you for using MedGPT360, your Scientific Overview generator for Personalised Medicine, available at <a href="https://medgpt360.guidelines-bot.com/" target='_blank'>medgpt360.guidelines-bot.com</a> and associated internet domains. Your privacy is important to us, and we are committed to protecting your personal information. This privacy policy explains how we collect, use, and share your data when you use MedGPT360.</p>

<h2 className="text-3xl font-bold mb-2 text-left">What information do we collect?</h2>
<p className="mb-6 col-lg-8 ">
When you visit our website, we may collect certain information automatically from your device. This information may include your device type (mobile or web), browser-type (e.g. Chrome), broad geographic location (e.g. country or city-level location). We may also collect information about how your device has interacted with our website, including the pages accessed and links clicked.  

</p>
<p className="mb-6 col-lg-8 ">
We also collect the information that you voluntarily provide through our website. This includes any personal information that you choose to share, such as your name and email address.
</p>
<p className="mb-6 col-lg-8">Please note that if you use our contact form or feedback form without providing your email, you will remain fully anonymous, but we won&apos;t be able to reach out to you to answer your comments or feedback.</p>
<h2 className="text-3xl font-bold mb-2">How do we use your information?</h2>
<p className="mb-6 col-lg-8">
Collecting user devices and usage information enables us to better understand the visitors who come to our website, which technical setup we may need to support and what content is of interest to them.
We use the information you provide to improve and provide MedGPT360 service, and to communicate with you when necessary to fulfill that service. Additionally, we may use your information for marketing purposes, such as sending promotional emails about our services. However, we will only do so if you explicitly opt-in.
</p>
<h2 className="text-3xl font-bold mb-2">How do we protect your information?</h2>
<p  className="mb-6 col-lg-8">
We take appropriate measures to ensure the security of your personal information, including encryption and secure storage. We restrict access to your personal information to only those employees who need to access it to provide our services.
</p>

<h2 className="text-3xl font-bold mb-2">How do we share your information?</h2>

<p className="mb-6 col-lg-8">We do not sell or rent your personal information to third parties. We may share your personal information with our service providers and partners who help us provide our services.  The disease information entered in MedGPT360 input form may be transmitted anonymously to third party APIs in order to serve requests. We may also share your personal information if required by law or legal process.
</p>
<h2 className="text-3xl font-bold mb-2">Google Analytics</h2>
<p className="mb-6 col-lg-8">We use Google Analytics to collect anonymous data about how MedGPT360 is used, in order to improve our service. This data is collected using cookies and other similar technologies. For more information about how Google Analytics collects and processes data, please see Google&apos;s privacy policy.
</p>
<h2 className="text-3xl font-bold mb-2">Your rights</h2>
<p className="mb-6 col-lg-8">You have the right to access, correct, or delete your personal information at any time. You also have the right to object to the processing of your personal information. To exercise these rights, please contact us using the information provided below.
</p>
<h2 className="text-3xl font-bold mb-2">Changes to this policy</h2>
<p className="mb-6 col-lg-8">We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our website and update the “Last updated” date above. We encourage you to review this policy periodically.
</p>
<h2 className="text-3xl font-bold mb-2">Contact us</h2>

<p className="mb-6 col-lg-8">If you have any questions or concerns about this privacy policy, please <span className="contact-link" onClick={() => {
const contactFormElement = document.getElementById("contact-form");
if (contactFormElement !== null) {
    contactFormElement.style.display = "block";
}
}}>contact us</span>.  </p> 
 
 <div  id="contact-form" style={{display: "none",  width: "100%"}}>
 <ContactForm></ContactForm>
 </div>

 
<p className="mb-6">Thank you for using MedGPT360</p>
       </div>
        </div>
      );

}

export default Privacy;